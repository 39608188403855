
import Vue, { defineComponent, reactive } from 'vue';
import { Form } from '@/models/Form';
import type { IFieldType, IParsedFieldSet } from '@/types/forms';

export default defineComponent({
  props: {
    form: {
      type: Form,
    },
    formKey: {
      type: String,
    },
    formFieldValues: {
      type: Object as () => Record<string, unknown>,
      default: () => ({}),
    },
    formFieldOptions: {
      type: Object as () => Record<string, unknown>,
      default: () => ({}),
    },
    property: {
      type: Object,
      required: true,
    },
    unit: {
      type: Object,
      required: true,
    },
    unitTypeID: {
      type: Number,
    },
    career: {
      type: Object,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      curForm: undefined as Form | undefined,
      parsedFieldset: [] as IParsedFieldSet[],
      formData: {} as Record<string, unknown>,
      formValidations: {} as Record<string, Function[]>,
      recaptchaToken: '',
      multiStepCurrentStep: 0,
      parsedFields: {} as Record<number, IFieldType[]>,
      dataSources: {} as Record<string, unknown>,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.loadForm(this.formKey);
      },
    },
  },
  created() {
    this.loadForm(this.formKey);
  },
  methods: {
    getFieldDefault(field: IFieldType): unknown {
      if (this.formData[field.name] !== null) {
        return this.formData[field.name];
      }

      return this.formFieldValues[field.name] !== undefined
        ? this.formFieldValues[field.name]
        : field.defaultValue;
    },

    fieldUpdated(value: string, field: IFieldType) {
      Vue.set(this.formData, field.name, value);
      if (field.dataSourceType) {
        Vue.set(this.dataSources, field.dataSourceType, parseInt(value, 10));
      }
    },

    analyticsCallback() {
      if (!this.curForm) {
        return;
      }

      let trackingCode;

      try {
        trackingCode = JSON.parse(this.curForm.trackingCodeOnSuccess as string);
      } catch (e) {
        //
      }

      if (trackingCode !== undefined && trackingCode.onSubmit !== undefined) {
        if (
          trackingCode.onSubmit.target !== undefined &&
          trackingCode.onSubmit.target === 'blank'
        ) {
          window.open(trackingCode.onSubmit.link);
        } else {
          window.location.href = trackingCode.onSubmit.link;
        }
      }
    },

    getAnalyticsLabel(): string {
      if (this.property && this.unit) {
        return `${this.property.buildingName} - ${this.unit.typeName}`;
      }

      if (this.property) {
        return this.property.buildingName as string;
      }

      if (this.unit) {
        return this.unit.typeName as string;
      }

      return '';
    },

    validationRules(field: IFieldType): Function[] {
      const fieldRules: Function[] = [];

      if (
        field.conditionalParentField !== undefined &&
        field.conditionalValueType !== undefined &&
        field.conditionalSpecificValue !== undefined &&
        field.required
      ) {
        switch (field.conditionalValueType) {
          case 'equal':
            fieldRules.push(
              this.requiredIf(
                (): boolean =>
                  this.formData[field.conditionalParentField as string] ===
                  field.conditionalSpecificValue
              )
            );
            break;
          case 'not_equal':
            fieldRules.push(
              this.requiredUnless(
                (): boolean =>
                  this.formData[field.conditionalParentField as string] ===
                  field.conditionalSpecificValue
              )
            );
            break;
          case 'empty':
            fieldRules.push(
              this.requiredIf(
                (): boolean =>
                  this.formData[field.conditionalParentField as string] === ''
              )
            );
            break;
          case 'not_empty':
            fieldRules.push(
              this.requiredUnless(
                (): boolean =>
                  this.formData[field.conditionalParentField as string] === ''
              )
            );
            break;
          default:
            break;
        }
      } else if (field.required) fieldRules.push(this.required);

      if (field.type === 'number') {
        fieldRules.push(this.numeric);

        if (field.min || field.min === 0) {
          fieldRules.push(
            (value: number) =>
              value >= field.min! || this.$t('error_min', { min: field.min! })
          );
        }

        if (field.max) {
          fieldRules.push(
            (value: number) =>
              value <= field.max! || this.$t('error_max', { max: field.max! })
          );
        }
      }

      if (field.validate) {
        switch (field.validate) {
          case 'alphanumeric':
            fieldRules.push(this.alphaNum);
            break;
          case 'numeric':
            fieldRules.push(this.numeric);
            break;
          case 'phone': // https://regexr.com/38pvb
            if (!field.required) {
              fieldRules.push(this.phoneNotRequired);
            } else {
              fieldRules.push(this.phone);
            }
            break;
          case 'email': // https://regexr.com/2rhq7
            fieldRules.push(this.email);
            break;
          case 'postal': // https://regexr.com/352r0
            fieldRules.push(this.postal);
            break;
          default:
            break;
        }
      }

      return fieldRules;
    },

    generateForm(newForm: Form) {
      this.curForm = newForm;
      this.parsedFieldset = [];
      this.parsedFields = {};
      this.formData = reactive({});
      this.multiStepCurrentStep = 0;

      let i = 0;

      Vue.set(this.parsedFieldset, i, {
        legend: this.curForm.name as string,
        step: i,
      });

      Vue.set(this.parsedFields, i, []);

      if (this.form && this.form.fields) {
        for (const field of this.form.fields) {
          if (
            field.type === 'paragraph' &&
            field.name.substring(0, 11) === 'new-section'
          ) {
            // Make sure not to have empty fieldset's
            if (this.parsedFields[i].length > 0) {
              i++;

              Vue.set(this.parsedFieldset, i, {
                legend: field.label,
                step: i,
              });

              Vue.set(this.parsedFields, i, []);
            }
          } else {
            Vue.set(
              this.formValidations,
              field.name,
              this.validationRules(field)
            );
            const newField = { ...field };
            newField.defaultValue = this.getFieldDefault(field);

            this.parsedFields[i].push(newField);

            if (field.type !== 'paragraph') {
              Vue.set(this.formData, field.name, null);

              if (field.dataSourceType) {
                Vue.set(this.dataSources, field.dataSourceType, null);
              }
            }
          }
        }
      }
    },

    resetForm() {
      if (this.curForm) {
        this.generateForm(this.curForm);
      }
    },

    /**
     * Takes care of the form submission, and gathering all the information required to submit it
     *
     * Note: This method assumes everything is "good to go" does not have any validation
     */
    async submitForm() {
      if (!this.curForm) {
        return;
      }

      let screenSize = 'unknown';

      if (window.screen.width >= 1200) {
        screenSize = 'desktop-size';
      } else if (window.screen.width >= 992) {
        screenSize = 'tablet-size';
      } else if (window.screen.width >= 480) {
        screenSize = 'mobile-size';
      }

      if (this.property) {
        this.formData.buildingId = this.property.id;
      }

      if (this.unit) {
        this.formData.unitId = this.unit.id;
      }

      if (this.unitTypeID) {
        this.formData.unitTypeId = this.unitTypeID;
      }

      if (this.career) {
        this.formData.careerId = this.career.id;
      }

      if (this.recaptchaToken) {
        this.formData.recaptchaToken = this.recaptchaToken;
      }

      const formSubmission = await this.curForm.submit(
        this.formData,
        this.lang,
        screenSize
      );

      if (formSubmission.status === 200) {
        this.$emit('success', formSubmission.data.successMessage);
        this.generateForm(this.curForm);

        let trackingCode;

        try {
          trackingCode = JSON.parse(
            this.curForm.trackingCodeOnSuccess as string
          );
        } catch (e) {
          //
        }

        if (
          trackingCode !== null &&
          typeof trackingCode !== 'undefined' &&
          trackingCode.event !== undefined
        ) {
          let options = {} as Record<string, unknown>;

          if (trackingCode.options !== undefined) {
            options = trackingCode.options;
          }

          options.event_callback = this.analyticsCallback;

          if (window.gtag) {
            window.gtag('event', trackingCode.event, options);
          }
        }
      } else {
        if (
          formSubmission.data.formErrors !== undefined &&
          formSubmission.data.formErrors.errorData.captcha !== undefined
        ) {
          for (const error of formSubmission.data.formErrors.errorData
            .captcha) {
            this.$emit('error', error);
          }
        }

        this.$emit('error', formSubmission.data.errorMessage);
      }

      this.recaptchaToken = '';
      this.$emit('reset-recaptcha');

      this.$emit('submitted');
      this.resetForm();
    },

    validateForm(): boolean {
      for (const field of Object.keys(this.formValidations)) {
        const rules = this.formValidations[field];
        for (const rule of rules) {
          if (rule(this.formData[field]) !== true) {
            return false;
          }
        }
      }

      return true;
    },

    processForm() {
      // Validate all the input before submitting to recaptcha
      if (!this.validateForm()) {
        this.$emit('error', 'fix-inputs');
        return;
      }

      this.$emit('invoke-recaptcha');
    },

    /**
     * Used to determine if the given field meets the requirements to be displayed
     */
    shouldRender(field: IFieldType) {
      if (field.conditionalParentField === undefined) {
        return true;
      }

      let meetsConditional = false;

      if (field.conditionalValueType !== undefined) {
        if (field.conditionalSpecificValue !== undefined) {
          switch (field.conditionalValueType) {
            case 'equal':
              meetsConditional =
                this.formData[field.conditionalParentField] ===
                field.conditionalSpecificValue;
              break;
            case 'not_equal':
              meetsConditional =
                this.formData[field.conditionalParentField] !==
                field.conditionalSpecificValue;
              break;
            default:
              break;
          }
        }

        switch (field.conditionalValueType) {
          case 'empty':
            meetsConditional =
              this.formData[field.conditionalParentField] === '' ||
              this.formData[field.conditionalParentField] === null;
            break;
          case 'not_empty':
            meetsConditional =
              this.formData[field.conditionalParentField] !== '' &&
              this.formData[field.conditionalParentField] !== null;
            break;
          default:
            break;
        }
      }

      if (!meetsConditional) {
        Vue.set(this.formData, field.name, null);
      }

      return meetsConditional;
    },
    /**
     * Loads the form, will attempt to retrieve it from store if available, otherwise will query for it and save the result
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async loadForm(formKey: string | undefined) {
      if (!formKey && this.form) {
        this.generateForm(this.form);
      }
    },
    /**
     * Method called by the recaptcha component once the captcha has been successfully completed
     */
    captchaCompleted(token: string) {
      this.recaptchaToken = token;
      this.submitForm();
    },
    /**
     * Method called by the recaptcha component once the captcha has expired
     */
    captchaExpired() {
      this.recaptchaToken = '';
      this.$emit('reset-recaptcha');
    },

    /**
     * Used by multi-step, triggers form validation for the given fieldset
     * if no errors are found go back to the previous step, otherwise displays the errors
     */
    previousFieldset() {
      if (!this.validateForm()) {
        this.$emit('error', 'fix-inputs');
        return;
      }

      this.multiStepCurrentStep--;
    },
    /**
     * Used by multi-step, triggers form validation for the given fieldset
     * if no errors are found continue to the next step, otherwise displays the errors
     */
    nextFieldset() {
      if (!this.validateForm()) {
        this.$emit('error', 'fix-inputs');
        return;
      }

      this.multiStepCurrentStep++;
    },

    requiredIf(propOrFunction: unknown): Function {
      return function (value: unknown) {
        if (typeof propOrFunction === 'function') {
          return propOrFunction(value);
        }

        return propOrFunction !== undefined && propOrFunction !== null;
      };
    },

    requiredUnless(propOrFunction: unknown): Function {
      return function (value: unknown) {
        if (typeof propOrFunction === 'function') {
          return !propOrFunction(value);
        }

        return !(propOrFunction !== undefined && propOrFunction !== null);
      };
    },
    req(value: unknown) {
      if (Array.isArray(value)) {
        return !!value.length;
      }

      if (value === undefined || value === null) {
        return false;
      }

      if (value === false) {
        return true;
      }

      if (value instanceof Date) {
        // invalid date won't pass
        return !Number.isNaN(value.getTime());
      }

      if (typeof value === 'object') {
        return Object.keys(value).length > 0;
      }

      return !!String(value).length;
    },

    required(value: unknown) {
      if (typeof value === 'string') {
        return !!value.trim() || this.$t('error_required');
      }
      return this.req(value) || this.$t('error_required');
    },

    alphaNum(value: string) {
      return /^[a-zA-Z0-9]*$/.test(value) || this.$t('error_alpha_num');
    },

    numeric(value: string) {
      return /^\d*(\.\d+)?$/.test(value) || this.$t('error_numeric');
    },

    phone(value: string) {
      return (
        /^(\+?\d{1,2})?(\s|-)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          value
        ) || this.$t('error_phone')
      );
    },

    phoneNotRequired(value: string) {
      if (value === null || value === undefined || value === '') {
        return true;
      } else {
        return this.phone(value);
      }
    },

    email(value: string) {
      return (
        // eslint-disable-next-line no-control-regex
        /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(
          value
        ) || this.$t('error_email')
      );
    },

    postal(value: string) {
      return (
        /^[abceghj-nprstvxyABCEGHJ-NPRSTVXY]{1}\d{1}[a-zA-Z]{1}\s?\d{1}[a-zA-Z]{1}\d{1}$/.test(
          value
        ) || this.$t('error_postal')
      );
    },
  },
  render(): any {
    return this.$scopedSlots.default?.({
      formKey: this.formKey,
      fieldset: this.parsedFieldset[this.multiStepCurrentStep],
      fieldsetIndex: this.multiStepCurrentStep,
      fields: this.parsedFields[this.multiStepCurrentStep],
      formData: this.formData,
      form: this.curForm,
      captchaCompleted: this.captchaCompleted,
      captchaExpired: this.captchaExpired,
      previousFieldset: this.previousFieldset,
      nextFieldset: this.nextFieldset,
      loadForm: this.loadForm,
      shouldRender: this.shouldRender,
      processForm: this.processForm,
      fieldUpdated: this.fieldUpdated,
      rules: this.formValidations,
    });
  },
});
