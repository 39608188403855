import type { FloorType } from '@/types/floor-type';
import { Unit } from './Unit';

export class Floor {
  public label: string;
  public number: string;
  public overlay: string;
  public viewbox: string;
  public elements: string;
  public css: string;
  public image: string;
  public units: Unit[];

  constructor(floor: FloorType) {
    this.label = floor.floorLabel;
    this.number = floor.floorNumber;
    this.overlay = floor.floorOverlay;
    this.viewbox = floor.floorplateViewbox;
    this.elements = floor.floorplateElements;
    this.css = floor.floorplateCss;
    this.image = floor.floorplateImage;
    this.units = floor.units.map(unit => new Unit(unit));
  }
}
