
import { defineComponent, PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { Unit } from '@/models/Unit';
import { Availability } from '@/types/unit-type';
import { decodeHtml } from '@/lib/helpers';
import { AssetHandler } from '@/lib/assetHandler';

export default defineComponent({
  name: 'FloorMap',
  props: {
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    hoveredUnit: {
      type: Unit,
    },
  },
  emits: ['unit-click', 'unit-hover'],
  data() {
    return {
      hoverUnit: undefined as undefined | Unit,
    };
  },
  computed: {
    useSvg(): boolean {
      return (
        this.curFloor.overlay.trim().length > 0 &&
        this.curFloor.elements.trim().length > 0
      );
    },
  },
  methods: {
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
    click(unit: Unit) {
      if (
        unit.available === Availability.unavailable ||
        this.units.find((fUnit: Unit) => fUnit.id === unit.id) === undefined
      ) {
        return;
      }

      this.$emit('unit-click', unit);
    },
    hover(unit: Unit | undefined) {
      if (unit && unit.available === Availability.unavailable) {
        return;
      }

      if (unit && this.hoverUnit && unit.id === this.hoverUnit.id) {
        return;
      }

      this.hoverUnit = unit;
      this.$emit('unit-hover', unit);
    },
    unitClasses(unit: Unit) {
      let classes: Record<string, boolean> = {};

      if (
        this.units.find((fUnit: Unit) => fUnit.id === unit.id) === undefined
      ) {
        classes = {
          'unit--unavailable': true,
        };
      } else {
        classes = {
          'unit--unavailable': unit.available === Availability.unavailable,
          'unit--available': unit.available === Availability.available,
          'unit--available-on': unit.available === Availability.availableOn,
          'unit--waitlist': unit.available === Availability.waitlist,
        };
      }

      return {
        ...classes,
        'unit--selected': this.hoveredUnit && unit.id === this.hoveredUnit.id,
      };
    },
    unitParts(unit: Unit) {
      const classes: Array<string> = [];

      if (
        this.units.find((fUnit: Unit) => fUnit.id === unit.id) === undefined
      ) {
        classes.push('floorplate-unit--unavailable')
      } else {
        if (unit.available === Availability.unavailable) {
          classes.push('floorplate-unit--unavailable')
        } else if (unit.available === Availability.available) {
          classes.push('floorplate-unit--available')
        } else if (unit.available === Availability.availableOn) {
          classes.push('floorplate-unit--available-on')
        } else if (unit.available === Availability.waitlist) {
          classes.push('floorplate-unit--waitlist')
        }
      }

      if (this.hoveredUnit && unit.id === this.hoveredUnit.id) {
        classes.push('floorplate-unit--selected');
      }

      return classes.join(' ');
    },
  },
});
