import axios from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { FpnConfig } from '@/types/httpClient';

export class HttpClient {
  public fpnKey = '';
  public axiosInstance: AxiosInstance;
  public axiosConfig: AxiosRequestConfig = {
    baseURL: ``,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  public hostOverride = '';
  public environment = 'production';

  public cdnCache = '';

  public cdnCacheControlOverride = '';
  public apiVersion = 'v1';

  public recaptchaPublicKey = '';

  constructor() {
    this.axiosInstance = axios.create(this.axiosConfig);
  }

  public setFpnConfig(options: FpnConfig): void {
    this.fpnKey = options.fpnKey;

    if (options.hostOverride) {
      this.hostOverride = options.hostOverride;
    }

    if (options.env) {
      this.environment = options.env;
    }

    // if (!/localhost/.test(window.location.hostname)) {
    //     if (options.cdnCacheOptions && options.cdnCacheOptions.cache) {
    //         this.cdnCache = options.cdnCacheOptions.cache
    //     }
    //
    //     if (options.cdnCacheOptions && options.cdnCacheOptions.cacheControlOverride) {
    //         this.cdnCacheControlOverride = options.cdnCacheOptions.cacheControlOverride
    //     }
    // }

    this.setBaseUrl();
  }

  private setBaseUrl(): void {
    let environmentPath = '';

    switch (this.environment) {
      case 'production':
        environmentPath = 'https://website-gateway.rentsync.com';
        break;
      case 'staging':
        environmentPath = 'https://website-gateway.staging.rentsync.dev';
        break;
      case 'demo':
        environmentPath = 'https://website-gateway.demo.rentsync.dev';
        break;
      case 'development':
        environmentPath = 'https://website-gateway.development.rentsync.dev';
        break;
    }
    //  this.environment === 'production'
    //   ? `https://fpn.rentsync.com`
    //    : `https://website-gateway-fpn-site-client-id-split.review.rentsync.dev`;
    // if (this.cdnCache === this.environment
    //     || (this.environment === 'staging' && this.cdnCache === 'all')
    //     || (this.environment === 'production' && this.cdnCache === 'all')) {
    //     environmentPath = this.environment === 'production'
    //         ? `https://website-gateway-cdn.rentsync.com`
    //         : `https://website-gateway-cdn.staging.rentsync.dev`
    // }
    // if (process.env.NODE_ENV === 'development' && this.hostOverride) {
    if (this.hostOverride) {
      this.axiosInstance.defaults.baseURL = `${this.hostOverride}/${this.apiVersion}/${this.fpnKey}`;
    } else {
      this.axiosInstance.defaults.baseURL = `${environmentPath}/${this.apiVersion}/${this.fpnKey}`;
    }
  }

  /**
   * Wrapper for Axios GET request.
   *
   * @param settings
   */
  public async get(settings: AxiosRequestConfig) {
    if (settings.method !== 'get') {
      settings.method = 'get';
    }

    return this.makeRequest(settings);
  }

  /**
   * Wrapper for Axios POST request.
   *
   * @param settings
   */
  public async post(settings: AxiosRequestConfig) {
    if (settings.method !== 'post') {
      settings.method = 'post';
    }

    return this.makeRequest(settings);
  }

  // eslint-disable-next-line class-methods-use-this
  private makeRequest(settings: AxiosRequestConfig): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      this.axiosInstance.request(settings).then(
        response => {
          resolve(response);
        },
        error => {
          if (error.response) {
            resolve(error.response);
          } else {
            reject(error);
          }
        }
      );
    });
  }
}

export const httpClient = new HttpClient();
