import {
  CardCustomField,
  CardField,
  ModalCustomField,
  ModalField,
} from '@/types/fields';

/**
 *
 */
export function isCardCustomField(card: CardField): card is CardCustomField {
  return (card as CardCustomField).customField !== undefined;
}

/**
 *
 */
export function isModalCustomField(field: ModalField): field is ModalCustomField {
  return (field as ModalCustomField).customField !== undefined;
}
