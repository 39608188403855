
import Vue, { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { Unit } from '@/models/Unit';
import type { UnitFields } from '@/types/unit-type';
import { VSelect, VBtn } from '@vuetify/lib/components';
import { FilterField } from '@/types/fields';
import { Floor } from '@/models/Floor';
import {interpolate} from "@/lib/interpolation";
import { Availability } from '@/types/unit-type';

export default defineComponent({
  name: 'UnitFilters',
  components: {
    VSelect,
    VBtn,
  },
  props: {
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    filterFields: {
      type: Array as PropType<FilterField[]>,
      required: true,
    },
    floors: {
      type: Array as PropType<Floor[]>,
      required: true,
    },
  },
  emits: ['filters-changed', 'update', 'set-floor'],
  data() {
    return {
      filterOptions: {} as Record<
        keyof Unit,
        { label: string; value: unknown }
      >,
      filterValues: {} as UnitFields,
    };
  },
  watch: {
    units() {
      this.updateFilters();
    },
    filterValues: {
      handler() {
        this.$emit('filters-changed', this.filterValues);
      },
      deep: true,
    },
  },
  created() {
    this.resetFilters();
    this.updateFilters();
  },
  methods: {
    resetFilters() {
      for (const filter of this.filterFields) {
        Vue.set(this.filterValues, filter.field, []);
      }

      this.$emit('filters-changed', this.filterValues);
    },
    updateFilters() {
      this.filterOptions = {} as Record<
        keyof Unit,
        { label: string; value: unknown }
      >;

      for (const filter of this.filterFields) {
        const opts = [
          {
            label: filter.label,
            value: null,
            disabled: true
          }
        ] as { value: any, label: string | null, disabled?: boolean }[];
        let values: unknown[] = [];

        if (filter.field === 'available') {
          if (this.units.find(unit => unit.available === Availability.available)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.available),
              value: Availability.available,
            });
          }

          if (this.units.find(unit => unit.available === Availability.unavailable)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.unavailable),
              value: Availability.unavailable,
            });
          }

          if (this.units.find(unit => unit.available === Availability.waitlist)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.waitlist),
              value: Availability.waitlist,
            });
          }

          if (this.units.find(unit => unit.available === Availability.availableOn)) {
            opts.push({
              label: interpolate(filter.format, filter, Availability.availableOn),
              value: Availability.availableOn,
            });
          }
        } else if (filter.increments === 0) {
          for (const unit of this.units) {
            const value = unit[filter.field];

            if (value !== null && !values.includes(value)) {
              values.push(value)
            }
          }

          values = values.sort()

          for (const value of values) {
            opts.push({
              value,
              label: interpolate(filter.format, filter, value),
            });
          }
        } else {
          let min = 9001;
          let max = 0;

          for (const unit of this.units) {
            const field = unit[filter.field];
            if (field && !Number.isNaN(Number.parseInt(field as any, 10))) {
              const parsedField = Number.parseInt(field as any, 10);

              if (parsedField < min) {
                min = typeof field === 'string' ? Number.parseInt(field) : field as number;
              } else if (parsedField > max) {
                max = typeof field === 'string' ? Number.parseInt(field) : field as number;
              }
            }
          }

          const increment = filter.increments;

          min = Math.floor(min / increment) * increment;
          max = Math.ceil(max / increment) * increment;

          if (filter.filter === 'min') {
            max -= increment;
          } else if (filter.filter === 'max') {
            min += increment;
          }

          // If the min and max are the same, we don't want to show any options
          // initialize the min value to roundedLowerBound and max value to upperBound to ensure that the range is inclusive
          for (let i = min; i <= max; i += increment) {
            opts.push({
              value: i,
              label: interpolate(filter.format, filter, i),
            });
          }
        }

        Vue.set(this.filterOptions, filter.field, opts);
      }
    },
    filtersButton(type: string) {
      const elementContainer = this.$refs[type] as Element;

      if (elementContainer) {
        elementContainer.classList.toggle('active');
      }
    },
    floorClicked(floor: Floor) {
      this.$emit('set-floor', floor);

      this.filtersButton('floors');
    },
  },
});
