// import { en } from 'vuetify/locale'

export default {
  // ...en,

  details: 'Details',
  no_results: 'No results where found',
  description: 'Description',
  unit_number: 'Unit {number}',
  inquire: 'Inquire',
  available_now: 'Available Now',
  unavailable: 'Unavailable',
  waitlist: 'Waitlist',
  waitlist_only: 'Waitlist only',
  available_on: 'Available on {date}',
  rent: 'Rent',
  beds: 'Bedrooms',
  bedrooms: 'Batchelor | {count} Bedroom | {count} Bedrooms',
  bath: 'Baths',
  baths: 'No bathrooms | {count} Bathroom | {count} Bathrooms',
  size: 'Size',
  sqft: 'SqFt | {count} SqFt',
  floor: 'Floor',
  availability: 'Availability',
  floor_number: 'Floor {number}',
  available_units: 'No units available | {count} unit available | {count} units available',
  submit: 'Submit',
  yes: 'Yes',
  no: 'No',

  // Form validation
  error_required: 'Field is required',
  error_alpha_num: 'Must only use alpha numeric characters',
  error_numeric: 'Must only use numbers',
  error_phone: 'Must be a valid phone number',
  error_email: 'Must be a valid email',
  error_postal: 'Must be a valid postal code',
  error_min: 'Must be at least {min}',
  error_max: 'Must be at most {max}',
  reset: 'Reset',
  view_gallery: 'View gallery',
  download_pdf: 'Download PDF',
  pets: 'Pets',
  pet_friendly: 'Pet friendly',
  small_dogs: 'Small dogs',
  large_dogs: 'Large dogs',
  cats: 'Cats',
  pets_not_allowed: 'Pets not allowed',
  enlarge: 'Enlarge',
  'floor_#': 'Floor {number}',
  filters: 'Filters',
  floors: 'Floors',
  loading_status: 'Floorplan Navigator is loading',
  '3d_loading': '3D Rendering is Loading',
  cancel: 'Cancel',
  ok: 'OK',
  form_error: 'There was an error while submitting the form, please try again later',
  building_photo: 'building photo',
  suite_list: 'Suite list'
};
