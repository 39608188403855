/** Vuetify Plugin */
import Vuetify from '@vuetify/lib/framework';
import Vue from 'vue';

// Locale
import i18n from './i18n';
import { en } from '@vuetify/lib/locale';
import fpnEn from '@/locales/en';

export default createVuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    current: navigator.language,
    locales: {
      en: {
        ...en,
        ...fpnEn,
      },
    },
    t: (key: string, ...params: any[]) => i18n.t(key, params) as string,
  },
  theme: {
    options: {
      themeCache: {
        // https://vuetifyjs.com/features/theme/#section-30ad30e330c330b730e5
        get: (key: any) => {
          return localStorage.getItem(JSON.stringify(key));
        },
        set: (key: any, value: string) => {
          localStorage.setItem(JSON.stringify(key), value);
        },
      },
      customProperties: true,
    },
  },
});

/** Create Vuetify */
export function createVuetify(options: any): Vuetify {
  Vue.use(Vuetify);
  return new Vuetify(options);
}
