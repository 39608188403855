import { load } from 'webfontloader';
/**
 * Plugins/webfontloader.js
 *
 * Webfontloader documentation: https://github.com/typekit/webfontloader
 */
export function loadFonts(root?: Element) {
  let parentElement = document.head as Element | HTMLElement | ShadowRoot;

  if (root) {
    parentElement = root;
  }
  /** dns-prefetch */
  const prefetch = document.createElement('link');
  prefetch.rel = 'dns-prefetch';
  prefetch.href = '//fonts.googleapis.com';
  parentElement.append(prefetch);

  /** Preconnect */
  const preconnect = document.createElement('link');
  preconnect.rel = 'preconnect';
  preconnect.href = 'https://fonts.gstatic.com';
  parentElement.append(preconnect);

  /** Webfont Config */
  const WebFontConfig = {
    google: {
      families: [
        'Roboto:100,300,400,500,700,900&display=swap',
        /*
        // if you use Noto Sans, replace bellow lines.
        'Noto+Sans:100,300,400,500,700,900',
        'Noto+Sans+JP:100,300,400,500,700,900',
        'Noto+Sans+KR:100,300,400,500,700,900',
        'Noto+Sans+Mono:100,300,400,500,700,900',
        */
        // Emoji Fix
        'Noto+Colr+Emoji+Glyf:400',
      ],
    },
    active: () => {
      sessionStorage.fonts = true;
    },
  };
  load(WebFontConfig);

  const mdi = document.createElement('style');

  let pathToAssets = '';
  if (process.env.VUE_APP_CDN_ENVIRONMENT) {
    if (
      process.env.CI_COMMIT_REF_SLUG &&
      process.env.CI_COMMIT_REF_SLUG !== 'development' &&
      process.env.CI_COMMIT_REF_SLUG !== 'main'
    ) {
      pathToAssets = `https://cdn.rentsync.com/${process.env.VUE_APP_CDN_ENVIRONMENT}/fpn/${process.env.CI_COMMIT_REF_SLUG}/`;
    } else {
      pathToAssets = `https://cdn.rentsync.com/${process.env.VUE_APP_CDN_ENVIRONMENT}/fpn/`;
    }
  }
  const fontFace = `
  @font-face{
    font-family:Material Design Icons;
    src:url(${pathToAssets}fonts/materialdesignicons-webfont.eot);
    src:url(${pathToAssets}fonts/materialdesignicons-webfont.eot?#iefix) format("embedded-opentype"),
    url(${pathToAssets}fonts/materialdesignicons-webfont.woff2) format("woff2"),
    url(${pathToAssets}fonts/materialdesignicons-webfont.woff) format("woff"),
    url(${pathToAssets}fonts/materialdesignicons-webfont.ttf) format("truetype");
    font-weight:400;
    font-style:normal;
  }
  `;
  mdi.innerText = fontFace.replace(/(\n)*/gm, '');
  document.head.append(mdi);
}
