
import { defineComponent } from 'vue';
import { Unit } from '@/models/Unit';
import { AssetHandler } from '@/lib/assetHandler';
import { VCarousel, VCarouselItem } from '@vuetify/lib/components';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
    VCarousel,
    VCarouselItem,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
      index: 0,
    };
  },
  methods: {
    showModal() {
      this.index = 0;

      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    getPath(image: string) {
      return AssetHandler.getImagePath(`gallery/1152/${image}`);
    },
  },
});
