import type {
  UnitCustomFields,
  UnitFloorplanType,
  UnitPhotoType,
  UnitType,
  VideoType,
} from '@/types/unit-type';
import { availabilityToString, formatDate } from '@/lib/helpers';
import { AvailabilityTypes } from '@/types/fields';

export class Unit {
  public id: number;

  public typeName: string | null;

  public number: string;

  public bath: number;

  public bed: number;

  public deposit: string | null;

  public sqFt: string | null;

  public rate: string | null;

  public dateMod: string | null;

  public den: boolean;

  public floor: string | null;

  public furnished: boolean;

  public shortTerm: boolean | null;

  public luxury: boolean;

  public executive: boolean;

  public penthouse: boolean;

  public waterfront: boolean;

  public accessible: boolean;

  public orientation:
    | 'north'
    | 'northeast'
    | 'east'
    | 'southeast'
    | 'south'
    | 'southwest'
    | 'west'
    | 'northwest'
    | null;

  public leaseTerm:
    | '1-month'
    | '2-month'
    | '3-month'
    | '6-month'
    | '12-month'
    | 'other'
    | null;

  public description: string | null;

  public available: AvailabilityTypes;

  public availabilityDate: string | null;

  public floorplans: UnitFloorplanType[];

  public photos: UnitPhotoType[];

  public customFields: UnitCustomFields;

  public video: string;

  public virtualTour?: string;

  public unitCoordinates: string

  public applicationUrl?: string

  constructor(unit: UnitType) {
    this.id = unit.id;
    this.typeName = unit.typeName;
    this.number = unit.number;
    this.bath = unit.bath;
    this.bed = unit.bed;
    this.deposit = unit.deposit;
    this.sqFt = unit.sqFt;
    this.rate = unit.rate;
    this.dateMod = unit.dateMod;
    this.den = unit.den === 'yes';
    this.floor = unit.floor;
    this.furnished = unit.furnished === 1;
    this.shortTerm = unit.shortTerm === 1;
    this.luxury = unit.luxury === 1;
    this.executive = unit.executive === 1;
    this.penthouse = unit.penthouse === 1;
    this.waterfront = unit.waterfront === 1;
    this.accessible = unit.accessible === 1;
    this.orientation = unit.orientation;
    this.leaseTerm = unit.leaseTerm;
    this.description = unit.description;
    this.availabilityDate = unit.availabilityDate && unit.availabilityDate !== '0000-00-00' && new Date(unit.availabilityDate.replace(/-/g, '/')) > new Date() ? formatDate(unit.availabilityDate) : '';
    this.floorplans = unit.floorplans;
    this.photos = unit.photos;
    this.customFields = unit.customFields;
    this.video = unit.video;
    this.virtualTour = unit.virtualTour;
    this.unitCoordinates = unit.unitCoordinates;
    this.available = availabilityToString(this, unit.available);
    this.applicationUrl = unit.applicationUrl;
  }
}
