
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { Availability } from '@/types/unit-type';
import { VIcon } from '@vuetify/lib/components';
import { Unit } from '@/models/Unit';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { interpolate } from '@/lib/interpolation';

export default defineComponent({
  name: 'FloorSelect',
  components: {
    VIcon,
  },
  props: {
    floorplanNavigator: {
      type: FloorplanNavigator,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    floors: {
      type: Array as PropType<Floor[]>,
      required: true,
    },
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
  },
  emits: ['change-floor'],
  computed: {
    floorSelectTitle() {
      // If the label contains anything that's not a number
      if (!/^\d+$/.test(this.curFloor.label)) {
        return interpolate(
          this.curFloor.label,
          this.curFloor,
          this.curFloor.number
        );
      }

      return this.$t('floor_number', { number: this.curFloor.number });
    },
    floorSelectSubtitle() {
      if (this.floorplanNavigator.settings.floorSelectSubtitle) {
        return interpolate(
          this.floorplanNavigator.settings.floorSelectSubtitle,
          {
            available: this.availableUnits,
            availableOn: this.availableOnUnits,
            availableNow: this.availableNowUnits,
          },
          this.availableUnits
        );
      }

      return this.$tc('available_units', this.availableUnits, { count: this.availableUnits });
    },
    availableUnits() {
      return this.units.filter(
        unit => unit.available === Availability.available || unit.available === Availability.availableOn
      ).length;
    },
    availableOnUnits() {
      return this.units.filter(
        unit => unit.available === Availability.availableOn
      ).length;
    },
    availableNowUnits() {
      return this.units.filter(
        unit => unit.available === Availability.available
      ).length;
    },
  },
});
