// import { en } from 'vuetify/locale'

export default {
  // ...en,

  details: 'Détails',
  no_results: 'Aucun Resultat',
  description: 'Description',
  unit_number: 'Unité {number}',
  inquire: 'Renseigner Vous',
  available_now: 'Disponible',
  unavailable: 'Non Disponible',
  waitlist: "Liste D'attente",
  waitlist_only: "Liste D'attente",
  available_on: 'Disponible le {date}',
  rent: 'Loyer',
  beds: 'Chambre(s)',
  bedrooms: 'Studio | {count} chambre | {count} Chambres',
  bath: 'Salle de Bains',
  baths: 'Sans Toilette | {count} toilette | {count} toilettes',
  size: 'Taille',
  sqft: 'Pi² | {count} Pi²',
  floor: 'Étage',
  availability: 'Disponibilité',
  floor_number: '{number} étage',
  available_units:
    'Aucune unité disponible | {count} Unité Disponible | {count} Unités Disponibles',
  submit: 'Soumettre',
  yes: 'Oui',
  no: 'Non',

  // Form validation
  error_required: 'Obligatoir',
  error_alpha_num: 'Doit utiliser des caractères alphanumériques',
  error_numeric: 'Doit utiliser des numéros',
  error_phone: 'Doit être un numero de telephone valide',
  error_email: 'Doit être un courriel valide',
  error_postal: 'Doit être un code postal valide',
  error_min: 'doit être au moins {min}',
  error_max: 'doit être au maximum {max}',
  reset: 'Réinitialiser',
  view_gallery: 'Voir la Galerie',
  download_pdf: 'Télécharger le PDF',
  pets: 'Animaux',
  pet_friendly: 'Accept les Animaux',
  small_dogs: 'Petit Chiens',
  large_dogs: 'Grand Chiens',
  cats: 'Chats',
  pets_not_allowed: 'Les animaux ne sont pas permis',
  enlarge: 'Agrandir',
  'floor_#': '{number} Étage',
  filters: 'Filtres',
  floors: 'Étages',
  loading_status: "Le navigateur de plan d'étage est en cours de chargement",
  '3d_status': 'Le rendu 3D est en cours de chargement',
  cancel: 'Annuler',
  ok: "D'accord",
  form_error:
    "Une erreur s'est produite lors de la soumission du formulaire, veuillez réessayer plus tard",
  building_photo: 'photographie de construction',
  suite_list: 'Liste des suites',
};
