import type {
  PropertyType,
  IPropertyParkingTypeRates,
  PropertyPanelsType,
} from '@/types/property';

export class Property {
  public id: number;

  public buildingName: string;

  public buildingHeader?: string | null;

  public intersection?: string | null;

  public neighbourhood?: string | null;

  public streetNumber?: string | null;

  public streetName?: string | null;

  public postal?: string | null;

  public contactName?: string | null;

  public phone?: string | null;

  public extension?: string | null;

  public altPhone?: string | null;

  public altPhoneExt?: string | null;

  public email?: string | null;

  public adminEmail?: string | null;

  public fax?: string | null;

  public officeHours?: string | null;

  public parking?: string | null;

  public parkingIndoor?: string | null;

  public parkingOutdoor?: string | null;

  public parking2ndSpot?: string | null;

  public parkingTypeRates?: IPropertyParkingTypeRates;

  public petFriendly: boolean;

  public petsSmallDogs: boolean;

  public petsLargeDogs: boolean;

  public petsCats: boolean;

  public petsNotAllowed: boolean;

  public petsDetails?: string | null;

  // public panels: PropertyPanelsType;

  constructor(property: PropertyType) {
    this.id = property.id;
    this.buildingName = property.buildingName;
    this.buildingHeader = property.buildingHeader;
    this.intersection = property.intersection;
    this.neighbourhood = property.neighbourhood;
    this.streetNumber = property.streetNumber;
    this.streetName = property.contactName;
    this.postal = property.postal;
    this.contactName = property.contactName;
    this.phone = property.phone;
    this.extension = property.extension;
    this.altPhone = property.altPhone;
    this.altPhoneExt = property.altPhoneExt;
    this.email = property.email;
    this.adminEmail = property.adminEmail;
    this.fax = property.fax;
    this.officeHours = property.officeHours;
    this.parking = property.parking;
    this.parkingIndoor = property.parkingIndoor;
    this.parkingOutdoor = property.parkingOutdoor;
    this.parking2ndSpot = property.parking2ndSpot;
    this.parkingTypeRates = property.parkingTypeRates;
    this.petFriendly = property.petFriendly === 1;
    this.petsSmallDogs = property.petsSmallDogs === 1;
    this.petsLargeDogs = property.petsLargeDogs === 1;
    this.petsCats = property.petsCats === 1;
    this.petsNotAllowed = property.petsNotAllowed === 1;
    this.petsDetails = property.petsDetails;
    // this.panels = property.panels;

    // If available correct the values coming as string, and switch them to numbers
    // if (this.panels.showUnitStatus) {
    //   this.panels.showUnitStatus = this.panels.showUnitStatus.map((value) => {
    //     return parseInt(value as unknown as string);
    //   });
    // }
  }
}
