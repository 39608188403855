
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { decodeHtml } from '@/lib/helpers';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import HauzdFrame from '@/hauzd';
import {
  HauzdLoadPressureChangedEvent,
  HauzdTwinSettings,
} from '@/types/hauzd';
import LoadingCircle from '@/components/LoadingCircle.vue';

export default defineComponent({
  name: 'Property3D',
  components: { LoadingCircle },
  props: {
    floorplanNavigator: {
      type: FloorplanNavigator,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    floors: {
      type: Array as PropType<Array<Floor>>,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  emits: ['floor-click'],
  data() {
    return {
      hauzdFrame: {} as HauzdFrame,
      floorToHauzdMapping: [] as Array<{
        hauzd: string;
        id: number;
        floor: number;
      }>,
      loading: true,
    };
  },
  watch: {
    curFloor: {
      handler() {
        const floor = this.getHauzdFloorFromFPNFloor(this.curFloor);
        console.log(floor, this.curFloor.number);

        if (floor) {
          this.hauzdFrame.setState({
            selRelAddr: floor,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!document.querySelector('script[src="https://hauzd.app/frame/js"]')) {
      const el = document.createElement('script');
      el.src = 'https://hauzd.app/frame/js';
      document.head.append(el);

      this.waitForHauzdToLoad();
    } else {
      this.setupHauzd();
    }
  },
  methods: {
    async waitForHauzdToLoad() {
      do {
        await new Promise(resolve => setTimeout(resolve, 50));
      } while (window['hauzd.embed'] === undefined);
      await this.setupHauzd();
    },
    async setupHauzd() {
      const { HauzdFrame, enums } = window['hauzd.embed'];

      this.hauzdFrame = new HauzdFrame({
        url: this.floorplanNavigator.instanceSettings.hauzd.url,
        settings: {
          showUI: false,
          showCameraCtrl: true,
          disableDiscl: true,
          disableEnvMapBack: true,
          backLinearGrad: [
            { color: [0.8, 0.8, 0.8, 1], ratio: 0.0 },
            { color: [1, 1, 1, 1], ratio: 0.5 },
          ],
          backColor: [1, 1, 1],
        },
        state: {
          scr: enums.SCR_NODE,
          scrPopup: '',
          lang: this.lang,
        },
        element: this.$refs.iframe,
      });

      this.hauzdFrame.on(enums.STATE_CHANGED, this.hauzdAppStateChanged);
      this.hauzdFrame.on(
        enums.LOAD_PRESSURE_CHANGED,
        this.hauzdLoadPressureChanged
      );

      const projectHierarchy = await this.hauzdFrame.getProjectHierarchy();
      const building = projectHierarchy.hierarchy.find(
        entry => entry.category === 'Building'
      );

      if (building) {
        const floors = building?.opts[0].children;

        this.floorToHauzdMapping = floors.map(floor => ({
          floor: floor.props.floorIdx,
          hauzd: floor.name,
          id: floor.id,
        }));
      }

      const floorsToDisable: HauzdTwinSettings = {};

      this.floorToHauzdMapping.forEach(floorMap => {
        const instance = this.floors.find(
          floor => Number.parseInt(floor.number, 10) === floorMap.floor
        );

        if (!instance) {
          floorsToDisable[floorMap.id] = { isTriggerEnabled: false };
        }
      });

      if (Object.keys(floorsToDisable).length > 0) {
        this.hauzdFrame.updateSettings({
          showUI: false,
          showCameraCtrl: true,
          disableDiscl: true,
          disableEnvMapBack: true,
          backLinearGrad: [
            { color: [0.8, 0.8, 0.8, 1], ratio: 0.0 },
            { color: [1, 1, 1, 1], ratio: 0.5 },
          ],
          backColor: [1, 1, 1],
          twin: floorsToDisable,
        });
      }
    },
    getHauzdFloorFromFPNFloor(floor: Floor): string | undefined {
      return this.floorToHauzdMapping.find(
        map => Number.parseInt(floor.number, 10) === map.floor
      )?.hauzd;
    },
    getFPNFloorNumberFromHauzd(floor: string): number | undefined {
      return this.floorToHauzdMapping.find(map => floor === map.hauzd)?.floor;
    },
    hauzdLoadPressureChanged(msg: HauzdLoadPressureChangedEvent) {
      console.log(msg);
      if (msg.loadPressure <= 0.5) {
        this.loading = false;
      }
    },
    hauzdAppStateChanged(args: any) {
      if (args.source === 'cam' && args.state.selRelAddr) {
        const floorNumber = this.getFPNFloorNumberFromHauzd(
          args.state.selRelAddr
        );

        console.log('Hauzd floor clicked!', args.state.selRelAddr, floorNumber);

        this.floors.forEach(floor => {
          if (Number.parseInt(floor.number) === floorNumber) {
            this.$emit('floor-click', floor);
          }
        });
      }
    },
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
  },
});
