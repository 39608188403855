
import { defineComponent, PropType } from 'vue';
import { Unit } from '@/models/Unit';
import { Availability } from '@/types/unit-type';
import { VCard, VCardText, VCardTitle, VIcon } from '@vuetify/lib/components';
import { CardField, CardFieldByColumGroup, ModalField } from '@/types/fields';
import { interpolate } from '@/lib/interpolation';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { isCardCustomField, isModalCustomField } from '@/lib/typeGuards';

export default defineComponent({
  name: 'UnitCard',
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    cardFields: {
      type: Object as PropType<CardFieldByColumGroup>,
      required: true,
    },
    floorplanNavigator: {
      type: Object as PropType<FloorplanNavigator>,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: 0,
    };
  },
  methods: {
    getParts() {
      let parts = 'unit-listing-card';

      if (
        this.unit.available === Availability.available &&
        this.unit.availabilityDate !== '0000-00-00' &&
        this.unit.availabilityDate !== null
      ) {
        parts += ' unit-listing-card--available-on';
      } else if (this.unit.available === Availability.available) {
        parts += ' unit-listing-card--available';
      } else if (this.unit.available === Availability.waitlist) {
        parts += ' unit-listing-card--waitlist';
      } else {
        parts += ' unit-listing-card--unavailable';
      }

      return parts;
    },
    interpolate,
    interpolateCustom(field: CardField) {
      if (!isCardCustomField(field)) {
        const value =
          field.field === 'rate' && field.numberFormat === 'currency'
            ? Number.parseFloat(
                this.unit[field.field] as string
              ).toLocaleString('en-CA', {
                maximumFractionDigits: 2,
              })
            : this.unit[field.field];

        return interpolate(field.format, this.unit, value);
      }

      const value = this.unit.customFields[field.customField];

      return interpolate(field.format, this.unit, value);
    },
  },
});
