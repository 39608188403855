
import { defineComponent } from 'vue';
import { VProgressCircular } from '@vuetify/lib/components';

export default defineComponent({
  components: {
    VProgressCircular,
  },
  props: {
    text: {
      type: String
    }
  },
});
