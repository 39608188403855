export const customEventListArray = [
  'view-unit',
  'view-gallery',
  'view-virtual-tours',
  'view-videos',
  'pdf-download',
  'call-initiated',
  'load',
  'open-form',
  'custom',
] as const;

export const onlyOncePerSession = [
  'load'
];

export const onlyOncePerUnitEventsPerSession = [
  'view-unit',
  'view-gallery',
  'view-tours',
  'pdf-download',
  'call-initiated',
  'open-form',
  'custom',
];

export type TCustomEventList = typeof customEventListArray[number];
