import { httpClient } from './httpClient';
import type { AssetHostsType } from '@/types/assetHandler';
import { Client } from '@/types/fields';
import {isNotProduction} from "@/lib/helpers";

export class AssetHandle {
  protected assetHosts: AssetHostsType;

  protected selectedAssetHost: string;

  protected assetStoragePathClientKey: string;

  protected assetStoragePathSiteKey: string;

  constructor() {
    this.selectedAssetHost = 'gcp';
    this.assetStoragePathClientKey = '';
    this.assetStoragePathSiteKey = '';

    this.assetHosts = {
      gcp: {
        baseUrl: 'https://storage.googleapis.com/rentsync-assets',
        separator: '-',
      },
      aws: {
        baseUrl: 'https://s3.amazonaws.com/lws_lift',
        separator: '_',
      },
    };
  }

  /**
   *
   * @param assetHost
   * @param client
   */
  public setStoragePath(assetHost: string, client: Client) {
    let { environment, fpnKey } = httpClient;
    const urlParams = new URLSearchParams(window.location.search);

    if (isNotProduction()) {
      fpnKey = urlParams.get('site-key') ?? fpnKey;
      environment = urlParams.get('env') ?? environment;
    }

    this.selectedAssetHost = assetHost || 'gcp';
    const base = `${
      this.assetHosts[this.selectedAssetHost].baseUrl
    }${this.initEnv(environment)}`;
    this.assetStoragePathClientKey = `${base}/${client.clientKey}`;
    this.assetStoragePathSiteKey = `${base}/${fpnKey}`;
  }

  /**
   * Returns the base asset path
   */
  public getBaseAssetPath(type: 'property' | 'website' = 'property') {
    if (type === 'property') {
      return this.assetStoragePathClientKey;
    }
    return this.assetStoragePathSiteKey;
  }

  /**
   * Returns the full link to the floorplan
   *
   * @param assetPath -
   */
  public getFloorplanPath(assetPath: string) {
    return `${this.assetStoragePathClientKey}/files/floorplans/${assetPath}`;
  }

  /**
   * Return string to image asset
   *
   * @param assetPath -
   * @returns String
   */
  public getImagePath(assetPath: string) {
    return `${this.assetStoragePathClientKey}/images/${assetPath}`;
  }

  /**
   * Return string to image asset for a panel
   *
   * @param panelName
   * @param assetName
   * @returns String
   */
  public getPanelImagePath(
    panelName: string,
    assetName: string,
    type: 'property' | 'website' = 'property'
  ) {
    if (type === 'website') {
      return `${this.assetStoragePathSiteKey}/panels/${panelName}/files/${assetName}`;
    }
    return `${this.assetStoragePathClientKey}/panels/${panelName}/files/${assetName}`;
  }

  /**
   *
   * @param gatewayEnv
   */
  private initEnv(gatewayEnv: string) {
    if (gatewayEnv === 'production') {
      return '';
    }
    return `${this.assetHosts[this.selectedAssetHost].separator}${gatewayEnv}`;
  }
}

export const AssetHandler = new AssetHandle();
