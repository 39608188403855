
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import UnitCard from './UnitCard.vue';
import { Floor } from '@/models/Floor';
import { Unit } from '@/models/Unit';
import {CardFieldByColumGroup} from '@/types/fields';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import {Settings} from "@/types/settings";

export default defineComponent({
  name: 'UnitListing',
  components: {
    UnitCard,
  },
  props: {
    curFloor: {
      type: Floor,
      required: true,
    },
    hoveredUnit: {
      type: Unit,
    },
    units: {
      type: Array as PropType<Unit[]>,
      required: true,
    },
    cardFields: {
      type: Object as PropType<CardFieldByColumGroup>,
      required: true,
    },
    settings: {
      type: Object as PropType<Settings>,
      required: true,
    },
    floorplanNavigator: {
      type: Object as PropType<FloorplanNavigator>,
      required: true,
    },
  },
  emits: ['unit-click', 'unit-hover'],
  data() {
    return {
      hoverUnit: undefined as undefined | Unit,
    };
  },
  methods: {
    hover(unit: Unit | undefined) {
      if (this.hoverUnit && unit && unit.id === this.hoverUnit?.id) {
        return;
      }

      this.hoverUnit = unit;
      this.$emit('unit-hover', unit);
    },
  },
});
