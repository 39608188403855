
import {defineComponent, PropType} from 'vue';
import { Unit } from '@/models/Unit';
import { decodeHtml } from '@/lib/helpers';
import { Property } from '@/models/Property';
import { AssetHandler } from '@/lib/assetHandler';
import {
  VCardSubtitle,
  VCard,
  VCardTitle,
  VCardText,
  VIcon,
  VBtn,
  VCarouselItem,
  VCarousel,
  VTabsItems,
  VTabItem,
  VTabs,
  VTab,
  VHover,
} from '@vuetify/lib/components';
import Modal from '@/components/Modal.vue';
import {
  AvailabilityForm,
  AvailabilityTypes,
  ModalButton,
  ModalField,
  ModalMediaButton,
  ModalTab,
} from '@/types/fields';
import {interpolate} from "../lib/interpolation";
import {FloorplanNavigator} from "@/models/FloorplanNavigator";
import { isModalCustomField } from '@/lib/typeGuards';
import {eventTracking} from "@/lib/customEvents";

export default defineComponent({
  name: 'UnitModal',
  components: {
    Modal,
    VCardSubtitle,
    VCard,
    VCardTitle,
    VCardText,
    VIcon,
    VBtn,
    VCarouselItem,
    VCarousel,
    VTabsItems,
    VTabItem,
    VTabs,
    VTab,
    VHover
  },
  props: {
    property: {
      type: Property,
      required: true,
    },
    floorplanNavigator: {
      type: FloorplanNavigator,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
    modalTabs: {
      type: Array as PropType<ModalTab[]>,
      required: true,
    },
    modalButtons: {
      type: Array as PropType<ModalButton[]>,
      required: true,
    },
    modalMediaButtons: {
      type: Array as PropType<ModalMediaButton[]>,
      required: true,
    },
    availabilityForms: {
      type: Object as PropType<Record<AvailabilityTypes, AvailabilityForm>>,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  emits: ['open-form', 'open-gallery', 'open-virtual-tours', 'open-videos', 'open-floorplans'],
  data() {
    return {
      tab: 0,
      activeFloorplanIndex: 0,
      floorplanIndex: 0,
    };
  },
  computed: {
    getTitle() {
      if (this.floorplanNavigator.settings.modalUnitTitle) {
        return interpolate(
          this.floorplanNavigator.settings.modalUnitTitle,
          this.unit,
          this.unit.number
        );
      }

      return this.$t('unit_number', { number: this.unit.number });
    },
    getSubtitle() {
      if (this.floorplanNavigator.settings.modalUnitSubtitle) {
        return interpolate(
          this.floorplanNavigator.settings.modalUnitSubtitle,
          this.unit,
          this.unit.typeName
        );
      }

      return this.unit.typeName;
    },
    displayFormButton() {
      return this.availabilityForm?.key || this.unit.applicationUrl;
    },
    floorplansNoPdf() {
      if (this.unit.floorplans) {
        return this.unit.floorplans.filter(
          floorplan => floorplan.image !== '' && floorplan.image !== null
        );
      }

      return [];
    },
    pdf() {
      if (this.unit.floorplans) {
        for (const floorplan of this.unit.floorplans) {
          if (floorplan.pdf) {
            return AssetHandler.getFloorplanPath(floorplan.pdf);
          }
        }
      }

      return '';
    },
    availabilityForm(): AvailabilityForm {
      return this.availabilityForms[this.unit.available]
    },
    formButtonText() {
      if (this.availabilityForm) {
        return this.availabilityForm.label;
      }

      return '';
    },
  },
  methods: {
    shouldShowTab(modalTab: ModalTab): boolean {
      return modalTab.fields.some((field) => {
        if (isModalCustomField(field)) {
          const value = this.unit.customFields[field.customField];

          return value !== '' && value !== undefined && value !== null
        }

        return this.unit[field.field] !== '' && this.unit[field.field] !== undefined && this.unit[field.field] !== null
      } );
    },
    getOverridableLink(button: ModalButton) {
      if (button.overridableCustomField && this.unit.customFields[button.overridableCustomField]) {
        return this.unit.customFields[button.overridableCustomField]
      }

      return button.link;
    },
    interpolate,
    interpolateCustom(modalField: ModalField) {
      if (!isModalCustomField(modalField)) {
        const value =
          modalField.field === 'rate' && modalField.numberFormat === 'currency'
            ? Number.parseFloat(
              this.unit[modalField.field] as string
            ).toLocaleString('en-CA', {
              maximumFractionDigits: 2,
            })
            : this.unit[modalField.field];

        return interpolate(modalField.format, this.unit, value)
      }

      const value = this.unit.customFields[modalField.customField]

      return interpolate(modalField.format, this.unit, value)
    },
    phoneEvent() {
      eventTracking('call-initiated', this.unit!.id);
    },
    linkEvent(button: ModalButton) {
      eventTracking('custom', this.unit!.id, button.eventKey);
    },
    pdfEvent() {
      eventTracking('pdf-download', this.unit!.id);
    },
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
    showModal() {
      this.floorplanIndex = 0;
      this.activeFloorplanIndex = 0;
      this.tab = 0;
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal && this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    getPath(image: string) {
      return AssetHandler.getImagePath(`floorplans/${image}`);
    },
  },
});
