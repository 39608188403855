
import { defineComponent } from 'vue';
import { VDialog, VOverlay, VIcon, VBtn } from '@vuetify/lib/components';

export default defineComponent({
  name: 'Modal',
  components: {
    VDialog,
    VOverlay,
    VIcon,
    VBtn
  },
  props: {
    contentClass: {
      type: String,
    },
    zIndex: {
      type: Number,
      default: 0,
    },
    modalAttrs: {
      type: Object,
    },
    activeModal: {
      type: String,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      //
      value: false,
    };
  },
  mounted() {
    document.body.addEventListener('keydown', this.closeOnEscape);
  },
  watch: {
    value() {
      if (this.value) {
        this.$emit('show')
      }
      if (!this.value) {
        this.$emit('hide')
      }
    }
  },
  methods: {
    open() {
      this.value = true;
    },
    hide() {
      this.value = false;
    },
    closeOnEscape(e: KeyboardEvent) {
      const activeModals =
        this.$root.$el.querySelectorAll('.v-overlay--active').length;

      if (
        e.key === 'Escape' &&
        (this.activeModal === this.modalName ||
          (this.modalName === 'unit' && activeModals === 1))
      ) {
        this.value = false;
      }
    },
  },
});
