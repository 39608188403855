import { httpClient } from '@/lib/httpClient';
// @ts-ignore
import { v4 as uuidV4 } from 'uuid';
import { getFpnSourceType, isNotProduction } from '@/lib/helpers';
import {
  onlyOncePerSession,
  onlyOncePerUnitEventsPerSession,
  TCustomEventList,
} from '@/types/customEvents';

export const eventTracking = async (
  baseEvent: TCustomEventList,
  suiteTypeId?: number,
  customEventKey?: string
) => {
  if (!window.fpnSessionId) {
    console.warn("No FPN session found, this shouldn't happen");
    window.fpnSessionId = uuidV4();
  }
  const action = customEventKey ? `${baseEvent}:${customEventKey}` : baseEvent;

  if (onlyOncePerSession.includes(action) && window.fpnClicks) {
    if (window.fpnClicks.includes(action)) {
      return;
    }
  }

  if (
    suiteTypeId &&
    onlyOncePerUnitEventsPerSession.includes(action) &&
    window.fpnUnitClicks
  ) {
    if (!window.fpnUnitClicks[action]) {
      window.fpnUnitClicks[action] = [];
    }

    if (window.fpnUnitClicks[action].includes(suiteTypeId)) {
      console.log(
        `Already triggered ${action} for the unit ${suiteTypeId} once, skipping`
      );
      return;
    } else {
      window.fpnUnitClicks[action].push(suiteTypeId);
    }
  }

  const data: Record<string, any> = {
    action,
    suiteTypeId,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
    },
    session: window.fpnSessionId,
    sourceType: getFpnSourceType()
  };

  if (document.referrer) {
    data.referrer = document.referrer;
  }

  if (isNotProduction()) {
    console.log('(Dev) Event Tracking:', data);
    return;
  }

  return httpClient.post({
    url: 'fpn-event-tracking',
    data,
  });
};
