import type { Unit } from '@/models/Unit';

export type UnitFloorplanType = {
  id: number;
  importId: string | null;
  suiteTypeId: number;
  name: string | null;
  description: string | null;
  size: string | null;
  price: string | null;
  image: string | null;
  imageWidth: number | null;
  imageHeight: number | null;
  pdf: string | null;
  orderBy: number;
};

export type UnitPhotoType = {
  buildingId: number;
  altText: string | null;
  image: string;
  suiteTypeId: number;
};

export type VideoType = {
  id: number;
  importId: number | null;
  clientId: number;
  siteId: number | null;
  buildingId: number;
  title: string | null;
  description: string | null;
  embedCode: string | null;
  orderBy: number;
  status: 'enabled' | 'disabled';
  created: string;
  createdUserId: number;
  modified: string | null;
  modifiedUserId: number | null;
};

export type UnitCustomFields = {
  [id: string]: unknown;
};

export type UnitType = {
  id: number;
  typeName: string;
  number: string;
  bath: number;
  bed: number;
  deposit: string | null;
  sqFt: string | null;
  rate: string | null;
  dateMod: string | null;
  den: 'yes' | 'no';
  floor: string | null;
  furnished: 1 | 0;
  shortTerm: 1 | 0 | null;
  luxury: 1 | 0;
  executive: 1 | 0;
  penthouse: 1 | 0 | null;
  waterfront: 1 | 0 | null;
  accessible: 1 | 0 | null;
  orientation:
    | 'north'
    | 'northeast'
    | 'east'
    | 'southeast'
    | 'south'
    | 'southwest'
    | 'west'
    | 'northwest'
    | null;
  leaseTerm:
    | '1-month'
    | '2-month'
    | '3-month'
    | '6-month'
    | '12-month'
    | 'other'
    | null;
  description: string | null;
  available: -1 | 0 | 1 | 2;
  availabilityDate: string | null;
  floorplans: UnitFloorplanType[];
  photos: UnitPhotoType[];
  customFields: UnitCustomFields;
  video: string;
  virtualTour?: string;
  unitCoordinates: string;
  applicationUrl?: string;
};

export type UnitFields = {
  [id in keyof Unit]: Unit[id][] | Unit[id];
};

export type UnitFilterType = {
  [id in keyof Unit]: Filter;
};

export type Filter = {
  type: 'select' | 'toggle';
  populateValuesBy: 'unitValueIncrements' | 'units';
  increments?: number;
};

export const Availability = {
  availableOn: 'availableOn',
  available: 'available',
  unavailable: 'notAvailable',
  waitlist: 'waitlist',
};
