import type { FormType, FieldType } from '@/types/form';
import type { IFormSubmissionResponse } from '@/types/forms';
import { httpClient } from '@/lib/httpClient';
import { getFpnSourceType, isProduction } from '@/lib/helpers';

export class Form {
  public id?: number;

  public key?: string;

  public revision?: number;

  public name?: string;

  public fields: FieldType[];

  public successMsg?: string | null;

  public errorMsg?: string | null;

  public trackingCodeOnLoad?: string | null;

  public trackingCodeOnSuccess?: string | null;

  public submitTitleTag?: string | null;

  constructor(form: FormType) {
    this.id = form.id;
    this.key = form.key;
    this.revision = form.revision;
    this.name = form.name;
    this.fields = form.fields;
    this.successMsg = form.successMsg;
    this.errorMsg = form.errorMsg;
    this.trackingCodeOnLoad = form.trackingCodeOnLoad;
    this.trackingCodeOnSuccess = form.trackingCodeOnSuccess;
    this.submitTitleTag = form.submitTitleTag;
  }

  public async submit(
    inData: Record<string, unknown>,
    language: string,
    source: string
  ): Promise<IFormSubmissionResponse> {
    const formData = new FormData();
    const data = { ...inData };

    formData.append('language', language);
    formData.append('source', source);
    formData.append('formSettings', JSON.stringify(this));

    // TODO: Once multiple file uploads are accepted change this logic to account for it
    for (const field of Object.keys(data)) {
      const fileList = data[field];
      if (fileList instanceof FileList) {
        if (fileList.length > 0) {
          formData.append(field, fileList[0]);
          data[field] = fileList[0].name;
        }
      }
    }

    formData.append('data', JSON.stringify(data));

    let testingEmail = false;
    const windowLocation = window.location.hostname;

    if (
      /\.rentsync\.dev/.test(windowLocation) ||
      /\.lws1\.com/.test(windowLocation)
    ) {
      testingEmail = true;
    }

    if (isProduction()) {
      formData.append(
        'screen',
        JSON.stringify({
          width: window.screen.width,
          height: window.screen.height,
        })
      );
      formData.append('session', window.fpnSessionId);
      formData.append('referrer', document.referrer);
      formData.append('sourceType', getFpnSourceType());
    }

    const response = await httpClient.post({
      url: 'form_responses',
      headers: {
        Referrer: document.referrer,
        'Content-Type': 'multipart/form-data',
        'Floorplan-Navigator': true,
        'test-form-submission': testingEmail ? true : false,
      },
      data: formData,
    });

    return {
      status: response.status ? response.status : 500,
      statusText: response.statusText ? response.statusText : null,
      data: response.data ? response.data : null,
    };
  }
}
