
import { defineComponent } from 'vue';
import PropertyImage from './PropertyImage.vue';
import FloorMap from './FloorMap.vue';
import UnitListing from './UnitListing.vue';
import UnitModal from './UnitModal.vue';
import type { Unit } from '@/models/Unit';
import UnitFormModal from './UnitFormModal.vue';
import UnitMediaModal from './UnitMediaModal.vue';
import type { Floor } from '@/models/Floor';
import FloorSelect from './FloorSelect.vue';
import { Availability } from '@/types/unit-type';
import type { UnitFields } from '@/types/unit-type';
import { FloorplanNavigator } from '@/models/FloorplanNavigator';
import { httpClient } from '@/lib/httpClient';
import UnitFilters from './UnitFilters.vue';
import { AssetHandler } from '@/lib/assetHandler';
import UnitGalleryModal from './UnitGalleryModal.vue';
import UnitFloorplanModal from './UnitFloorplanModal.vue';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { VApp } from '@vuetify/lib/components';
import { loadFonts } from '@/plugins/webfontloader';
import LoadingCircle from './LoadingCircle.vue';
import axios from "axios";
import { isNotProduction } from '@/lib/helpers';
import {eventTracking} from "@/lib/customEvents";
// @ts-ignore
import { v4 as uuidV4 } from 'uuid';
import Property3D from '@/components/Property3D.vue';

export default defineComponent({
  name: 'FloorplanNavigator',
  i18n,
  vuetify,
  components: {
    Property3D,
    UnitGalleryModal,
    UnitFloorplanModal,
    UnitMediaModal,
    UnitFilters,
    FloorSelect,
    UnitFormModal,
    UnitModal,
    UnitListing,
    FloorMap,
    PropertyImage,
    VApp,
    LoadingCircle,
  },
  props: {
    floorplanKey: {
      type: String
    },
    floorplanEnv: {
      type: String,
      default: 'production',
      validator: (val: string) =>
        ['production', 'staging', 'demo', 'development'].includes(val),
    },
    // Deprecated props
    propertyId: {
      type: Number,
    },
    showUnavailableUnits: {
      type: Boolean,
    },
    // Add the branding to aspen in the platform
    includeDefaultBranding: {
      type: Boolean,
    },
    // TODO: Once Soho italia's issues are addressed, remove this prop (breaking change)
    removeDefaultStructure: {
      type: Boolean,
    },
    includeContainer: {
      type: Boolean
    },
    // End deprecated props

    // Move to the platform
    enableListingAutoscroll: {
      type: Boolean,
    },
    disableListingAutoscrollAt: {
      type: Number,
      default: 1200,
    },
    // end move to the platform
    removeDefaultHeights: {
      type: Boolean,
    },
    appType: {
      type: String,
      required: true,
      validator: (val: string) => ['modal', 'listing'].includes(val),
    },
    unitModalPreset: {
      type: String,
      default: 'tall',
      validator: (val: string) => ['default', 'tall', 'none'].includes(val),
    },
    disableDefaultAnimation: {
      type: Boolean,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      selectedUnit: undefined as Unit | undefined,
      floorplanNavigator: {} as FloorplanNavigator,
      allUnitsById: {} as Record<number, Unit>,
      curFloorI: 0,
      hoveredUnit: undefined as undefined | Unit,
      hoveredListingUnit: undefined as undefined | Unit,
      selectedFilters: {} as UnitFields,
      activeFloorplan: 0,
      mediaType: 'videos',
      modalFpnEl: undefined as undefined | Element,
      listingFpnEl: undefined as undefined | Element,
      fpnEl: undefined as undefined | Element,
      activeModal: '',
      unitModalPresetSelection: '',
      authorized: true,
      waitForDataI: 0,
      insertDefaultBranding: false,
      observer: {} as IntersectionObserver,
      viewThreshold: 0.25,
    };
  },
  watch: {
    async lang() {
      this.$i18n.locale = this.lang;

      if (this.floorplanKey) {
        await this.getFloorplan();
      } else if (this.propertyId) {
        await this.getFloorplan(`,propertyId:${this.propertyId}`);
      }
    }
  },
  computed: {
    use3D(): boolean {
      return this.floorplanNavigator.instanceSettings
        && this.floorplanNavigator.instanceSettings.hauzd
        && !!this.floorplanNavigator.instanceSettings.hauzd.url
    },
    listingLayout(): string {
      return this.floorplanNavigator.listingLayout === 'simplified' ? 'default' : this.floorplanNavigator.listingLayout;
    },
    cssVariables() {
      let variables = '';

      if (Object.keys(this.floorplanNavigator).length > 0) {
        if (this.floorplanNavigator.settings.buildingOverlayOpacity) {
          variables = `--overlay-opacity: ${this.floorplanNavigator.settings.buildingOverlayOpacity};`;
        } else {
          variables = `--overlay-opacity: 0.5;`;
        }
      }

      return variables
    },
    floors(): Floor[] {
      if (!this.floorplanNavigator.settings.showUnavailableFloors) {
        let floors: Floor[] = [];

        this.floorplanNavigator.floors.forEach((floor) => {
          floor.units.some((unit: Unit) => {
            if (unit.available !== Availability.unavailable) {
              floors.push(floor);

              return true;
            }
          });
        });

        return floors;
      }

      return this.floorplanNavigator.floors;
    },
    curFloor(): Floor {
      return this.floors[this.curFloorI];
    },
    units(): Unit[] {
      return this.curFloor.units.reduce((unitList: Unit[], unit: Unit) => {
        if (this.floorplanNavigator.settings.showUnitStatus && this.floorplanNavigator.settings.showUnitStatus.includes(unit.available)) {
          unitList.push(unit);
        }

        if (this.floorplanNavigator.settings.showUnitStatus === undefined && (unit.available !== Availability.unavailable || this.showUnavailableUnits)) {
          unitList.push(unit);
        }

        return unitList;
      }, [] as Unit[]);
    },
    allUnits(): Unit[] {
      const units = [];

      for (const floor of this.floors) {
        for (const unit of floor.units) {
          if (this.floorplanNavigator.settings.showUnitStatus && this.floorplanNavigator.settings.showUnitStatus.includes(unit.available)) {
            units.push(unit);
          }

          if (this.floorplanNavigator.settings.showUnitStatus === undefined && (unit.available !== Availability.unavailable || this.showUnavailableUnits)) {
            units.push(unit);
          }
        }
      }

      return units;
    },
    filteredUnits(): Unit[] {
      if (this.isModal) {
        return [];
      }

      return this.units.reduce((unitList: Unit[], unit: Unit) => {
        const filterKeys = Object.keys(this.selectedFilters) as (keyof Unit)[];

        if (filterKeys.length > 0) {
          for (const filterKey of filterKeys) {
            const filter = this.floorplanNavigator.filterFields.find(
              filter => filter.field === filterKey
            );

            if (!filter) {
              continue;
            }

            let unitValue = unit[filterKey];
            let filterValue = this.selectedFilters[filterKey];
            const filterAction = filter.filter;
            if (
              unitValue === null ||
              unitValue === undefined ||
              filterValue === null ||
              filterValue === undefined ||
              (Array.isArray(filterValue) && filterValue.length === 0)
            ) {
              continue;
            }

            if (filterAction === 'min' || filterAction === 'max') {
              unitValue = Number.parseInt(unitValue as any, 10);
              filterValue = Number.parseInt(filterValue as any, 10);
            }

            if (unitValue < filterValue && filterAction === 'min') {
              return unitList;
            } else if (unitValue > filterValue && filterAction === 'max') {
              return unitList;
            } else if (unitValue !== filterValue && filterAction === 'exact') {
              return unitList;
            }
          }
        }

        unitList.push(unit);

        return unitList;
      }, [] as Unit[]);
    },
    isModal(): boolean {
      return this.appType === 'modal';
    },
    isListing(): boolean {
      return this.appType === 'listing';
    },
    floorplanLoaded(): boolean {
      return Object.keys(this.floorplanNavigator).length > 0;
    },
  },
  created() {
    this.unitModalPresetSelection = this.unitModalPreset;
    this.observer = new IntersectionObserver(this.loadIntersection, {
      threshold: [
        this.viewThreshold
      ]
    });
  },
  async mounted() {
    if (!window.fpnSessionId) {
      window.fpnSessionId = uuidV4();
      window.fpnUnitClicks = {};
      window.fpnClicks = [];
    }

    this.$i18n.locale = this.lang;
    let key = this.floorplanKey;
    let env = this.floorplanEnv

    if (isNotProduction(false)) {
      const urlParams = new URLSearchParams(window.location.search);
      const keyParam = urlParams.get('key');
      const langParam = urlParams.get('lang');
      const envParam = urlParams.get('env') || (keyParam !== null ? 'production' : null);

      if (keyParam !== null) {
        key = keyParam;
      }

      if (langParam !== null) {
        this.$i18n.locale = langParam;
      }

      if (envParam !== null) {
        env = envParam;
      }
    }

    if (key) {
      httpClient.setFpnConfig({
        fpnKey: key,
        env,
        // hostOverride: 'localhost:5015',
      });

      if (env === 'demo' || this.includeDefaultBranding) {
        this.insertDefaultBranding = true;
      }

      await this.getFloorplan();
    } else {
      if (!this.propertyId) {
        console.error('Missing data, please ensure property-id or floorplan-key is defined')
        return
      }

      httpClient.setFpnConfig({
        fpnKey: 'fpn_legacy',
        env,
      });

      await this.getFloorplan(`,propertyId:${this.propertyId}`);
    }

    if (!this.authorized) {
      return;
    }

    AssetHandler.setStoragePath('aws', this.floorplanNavigator.client);

    httpClient.recaptchaPublicKey =
      this.floorplanNavigator.recaptchaSiteKey;

    if (this.isListing) {
      this.changeFloor(0);
    }

    if (this.isModal) {
      this.allUnitsById = {};

      for (const floor of this.floors) {
        for (const unit of floor.units) {
          this.allUnitsById[unit.id] = unit;
        }
      }
    }

    loadFonts(this.$root.$el);
    this.addStyles();

    const fpns = document.body.querySelectorAll('floorplan-navigator');

    for (const fpn of fpns) {
      const appType = fpn.getAttribute('app-type');

      if (appType === 'modal' && this.appType !== 'modal') {
        this.modalFpnEl = fpn;
      }

      if (appType === 'listing' && this.appType !== 'listing') {
        this.listingFpnEl = fpn;
      }

      if (
        appType === this.appType ||
        (appType === null &&
          (this.appType === undefined || this.appType === ''))
      ) {
        this.fpnEl = fpn;
      }

      if (appType === 'modal' && this.appType === 'modal') {
        fpn.addEventListener('open-modal', e => {
          const data = (e as any).detail;

          if (this.allUnitsById && this.allUnitsById[data.unitId]) {
            const unit = this.allUnitsById[data.unitId];

            this.selectedUnit = unit;
            this.openModal(unit);
          }
        });

        fpn.addEventListener('open-floorplans', e => {
          const data = (e as any).detail;

          if (this.allUnitsById && this.allUnitsById[data.unitId]) {
            this.selectedUnit = this.allUnitsById[data.unitId];
            this.openFloorplans(data.activeFloorplan ?? 0);
          }
        });

        fpn.addEventListener('open-videos', e => {
          const data = (e as any).detail;

          if (this.allUnitsById && this.allUnitsById[data.unitId]) {
            this.selectedUnit = this.allUnitsById[data.unitId];
            this.openVideos();
          }
        });

        fpn.addEventListener('open-gallery', e => {
          const data = (e as any).detail;

          if (this.allUnitsById && this.allUnitsById[data.unitId]) {
            this.selectedUnit = this.allUnitsById[data.unitId];
            this.openGallery();
          }
        });
      }
    }

    this.emitFpnEvent('initialized');
  },
  methods: {
    loadIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= this.viewThreshold) {
          eventTracking('load');
          this.observer.disconnect();
        }
      })
    },
    setRatio(ratio: number) {
      (this.$refs.wrap as Element).setAttribute(
        'style',
        `--width-ratio-default: ${ratio};`
      );
    },
    async getFloorplan(queryParams: string = '') {
      if (!window.fpnData) {
        window.fpnData = {};
      }

      if (window.fpnData[httpClient.fpnKey] === undefined) {
        window.fpnData[httpClient.fpnKey] = null;

        try {
          const floorplanNavigatorResponse = await httpClient.get({
            url: `floorplan-navigator?where=lang:${this.$i18n.locale}${queryParams}`,
          });

          if (floorplanNavigatorResponse.status === 401) {
            this.authorized = false;
          } else {
            window.fpnData[httpClient.fpnKey] = floorplanNavigatorResponse.data;
            this.floorplanNavigator = new FloorplanNavigator(floorplanNavigatorResponse.data);
            // Wait some time for images to load.
            window.setTimeout(() => {
              this.observer.observe(this.$el)
            }, 500)
          }
        } catch (e) {
          if (axios.isAxiosError(e)) {
            this.authorized = e.status != '401'
          }
        }
      } else {
        await new Promise(resolve => this.recursiveWaitForFpnData(resolve));
      }
    },
    async recursiveWaitForFpnData(resolve: (value?: unknown) => void) {
      // Gives the request 30 seconds to load before calling it quits.
      do {
        if (window.fpnData && window.fpnData[httpClient.fpnKey] !== null) {
          this.floorplanNavigator = new FloorplanNavigator(window.fpnData[httpClient.fpnKey]!);
        } else {
          await new Promise(timeOutResolve => setTimeout(timeOutResolve, 500));
        }

        this.waitForDataI++;
      } while (Object.keys(this.floorplanNavigator).length === 0 && this.waitForDataI < 60);

      if (Object.keys(this.floorplanNavigator).length === 0) {
        this.authorized = false;
      }

      resolve();
    },
    addStyles() {
      let styles = this.$el.querySelector('#fpn-styles');

      if (!styles) {
        styles = document.createElement('style');
        styles.setAttribute('id', 'fpn-styles');
        this.$el.append(styles);
      }

      if (styles) {
        styles.innerHTML = this.floorplanNavigator.legacyStyles;
      }

      if (!document.querySelector('#fpn-head-styles')) {
        const headStyles = document.createElement('style');
        headStyles.setAttribute('id', 'fpn-head-styles');
        headStyles.innerHTML = `${this.floorplanNavigator.styles} `;
        if (this.floorplanNavigator.cssStyles) {
          headStyles.innerHTML += this.floorplanNavigator.cssStyles
        }
        document.head.append(headStyles)
      }
    },
    unitListingSelected(unit: Unit) {
      this.openModal(unit);
    },
    unitMapSelected(unit: Unit) {
      this.openModal(unit);
    },
    openFloorplans(activeFloorplan: number) {
      this.activeFloorplan = activeFloorplan;
      this.activeModal = 'floorplan';

      if (this.isModal) {
        (this.$refs.floorplans as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-floorplans', { activeFloorplan });
      }
    },
    openVideos() {
      this.mediaType = 'videos';
      this.activeModal = 'media';


      eventTracking('view-videos', this.selectedUnit!.id)

      if (this.isModal) {
        (this.$refs.media as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-videos');
      }
    },
    openVirtualTours() {
      this.mediaType = 'virtual-tours';
      this.activeModal = 'media';

      eventTracking('view-virtual-tours', this.selectedUnit!.id);

      if (this.isModal) {
        (this.$refs.media as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-virtual-tours');
      }
    },
    sendModalEvent(
      eventName: string,
      extraOptions: Record<string, unknown> = {}
    ) {
      if (this.selectedUnit) {
        const event = new CustomEvent(eventName, {
          detail: { unitId: this.selectedUnit.id, ...extraOptions },
        });

        if (this.appType !== 'modal') {
          this.modalFpnEl?.dispatchEvent(event);
        } else {
          this.fpnEl?.dispatchEvent(event);
        }
      }
    },
    emitFpnEvent(eventName: string, opts?: Record<string, unknown>) {
      const event = new CustomEvent(eventName, opts);
      this.fpnEl?.dispatchEvent(event);
    },
    async openModal(unit: Unit) {
      this.selectedUnit = unit;
      this.activeModal = 'unit';

      await this.$nextTick();

      if (this.isModal) {
        if (this.$refs && this.$refs.unitModal) {
          (this.$refs.unitModal as any).showModal();
        }

        eventTracking('view-unit', this.selectedUnit!.id)
      } else if (this.isListing) {
        this.sendModalEvent('open-modal');
      }
    },
    openForm() {
      eventTracking('open-form', this.selectedUnit!.id);
      if (!this.selectedUnit?.applicationUrl) {
        const availabilityForm = this.floorplanNavigator.availabilityForms[this.selectedUnit!.available];

        if (availabilityForm.type === 'form') {
          if (this.isModal) {
            this.activeModal = 'form';
            (this.$refs.form as any).showModal();
          } else if (this.isListing) {
            this.sendModalEvent('open-form');
          }
        } else {
          window
            .open(availabilityForm.link.replaceAll('&amp;', '&'), '_blank')
            ?.focus();
        }
      } else {
        this.sendModalEvent('open-application-url')
        window
          .open(this.selectedUnit?.applicationUrl.replaceAll('&amp;', '&'), '_blank')
          ?.focus();
      }
    },
    openGallery() {
      if (this.isModal) {
        eventTracking('view-gallery', this.selectedUnit!.id)

        this.activeModal = 'gallery';

        (this.$refs.gallery as any).showModal();
      } else if (this.isListing) {
        this.sendModalEvent('open-gallery');
      }
    },
    setFloor(floor: Floor) {
      this.curFloorI = this.floors.findIndex((fFloor) => fFloor.number === floor.number);

      let styles = this.$el.querySelector('#floor-styles');

      if (!styles) {
        styles = document.createElement('style');
        styles.setAttribute('id', 'floor-styles');
        this.$el.append(styles);
      }

      if (styles && floor.css) {
        styles.innerHTML = `.floor-map{${floor.css}}`;
      }
    },
    filtersChanged(values: UnitFields) {
      this.selectedFilters = values;
    },
    changeFloor(i: number) {
      this.curFloorI += i;

      if (this.curFloorI < 0) {
        this.curFloorI = this.floors.length - 1;
      } else if (this.curFloorI >= this.floors.length) {
        this.curFloorI = 0;
      }

      this.setFloor(this.floors[this.curFloorI]);
    },
    unitMapHover(unit: Unit | undefined) {
      this.$nextTick(() => {
        if (
          unit &&
          this.enableListingAutoscroll &&
          window.outerWidth > this.disableListingAutoscrollAt
        ) {
          const el = this.$el.querySelector(
            `.unit-card[data-unit-id="${unit.id}"]`
          );

          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }
        this.hoveredUnit = unit;
      });
    },
    unitListingHover(unit: Unit | undefined) {
      this.hoveredListingUnit = unit;
    },
  },
});
