import { httpClient } from '@/lib/httpClient';
import { Floor } from './Floor';
import type {
  FloorplanNavigatorType,
  TInstanceSettings,
} from '@/types/floorplan';
import { Property } from './Property';
import type { FormList } from '@/types/form';
import { Form } from './Form';
import {
  AvailabilityForm,
  FilterField,
  ModalButton,
  ModalMediaButton,
  Client,
  ModalTab,
  CardFieldByColumGroup,
  AvailabilityTypes,
} from '@/types/fields';
import { Settings } from '@/types/settings';

export class FloorplanNavigator {
  public buildingImage: string;
  public building: Property;
  public floors: Floor[];
  public forms: FormList;
  public cardFields: CardFieldByColumGroup;
  public modalTabs: ModalTab[];
  public modalButtons: ModalButton[];
  public modalMediaButtons: ModalMediaButton[];
  public availabilityForms: Record<AvailabilityTypes, AvailabilityForm>;
  public filterFields: FilterField[];
  public settings: Settings;
  public client: Client;
  public legacyStyles: string;
  public styles: string;
  public cssStyles?: string;
  public recaptchaSiteKey: string;
  public googleAnalyticsKey: string;
  public listingLayout: string;
  public instanceSettings: TInstanceSettings;

  constructor(floorplanNavigator: FloorplanNavigatorType) {
    this.buildingImage = floorplanNavigator.buildingImage;
    this.building = new Property(floorplanNavigator.buildingData);
    this.floors = floorplanNavigator.floorData.map(floor => new Floor(floor));
    this.forms = {};
    this.cardFields = floorplanNavigator.cardFields;
    this.modalTabs = floorplanNavigator.modalTabs;
    this.availabilityForms = floorplanNavigator.availabilityForms;
    this.filterFields = floorplanNavigator.filterFields.map(filter => {
      return {
        ...filter,
        increments: Number.parseInt(filter.increments),
      };
    });
    this.settings = floorplanNavigator.settings;
    this.modalButtons = floorplanNavigator.modalButtons;
    this.modalMediaButtons = floorplanNavigator.modalMediaButtons;
    this.client = floorplanNavigator.client;
    for (const form of Object.keys(floorplanNavigator.forms)) {
      this.forms[form] = new Form(floorplanNavigator.forms[form]);
    }
    this.legacyStyles = floorplanNavigator.legacyStyles;
    this.styles = floorplanNavigator.styles;
    this.cssStyles = floorplanNavigator.cssStyles;
    this.recaptchaSiteKey = floorplanNavigator.recaptchaSiteKey;
    this.googleAnalyticsKey = floorplanNavigator.googleAnalyticsKey;
    this.listingLayout = floorplanNavigator.listingLayout;
    this.instanceSettings = floorplanNavigator.instanceSettings;
  }
}
