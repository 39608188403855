
import { defineComponent } from 'vue';
import { Unit } from '@/models/Unit';
import { AssetHandler } from '@/lib/assetHandler';
import { VCarousel, VCarouselItem } from '@vuetify/lib/components';
import Modal from '@/components/Modal.vue';

export default defineComponent({
  components: {
    Modal,
    VCarousel,
    VCarouselItem,
  },
  props: {
    unit: {
      type: Unit,
      required: true,
    },
    activeFloorplan: {
      type: Number,
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    floorplansNoPdf() {
      if (this.unit.floorplans) {
        return this.unit.floorplans.filter(
          floorplan => floorplan.image !== '' && floorplan.image !== null
        );
      }
      return [];
    },
  },
  methods: {
    showModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).open();
      }
    },
    hideModal() {
      if (this.$refs.modal) {
        (this.$refs.modal as any).hide();
      }
    },
    getPath(image: string) {
      return AssetHandler.getImagePath(`floorplans/${image}`);
    },
  },
});
