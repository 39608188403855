
import Vue, { defineComponent } from 'vue';
import RenderlessFormBuilder from './renderless/RenderlessFormBuilder.vue';
import RecaptchaDefault from './RecaptchaDefault.vue';
import { Form } from '@/models/Form';
import {
  VForm,
  VTextField,
  VTextarea,
  VBtn,
  VDatePicker,
  VMenu,
  VSpacer,
  VAlert,
  VCheckbox,
  VSelect
} from '@vuetify/lib/components';
import { Property } from '@/models/Property';
import { Unit } from '@/models/Unit';

export default defineComponent({
  name: 'FormBuilder',
  components: {
    RenderlessFormBuilder,
    RecaptchaDefault,
    VForm,
    VTextField,
    VTextarea,
    VBtn,
    VDatePicker,
    VMenu,
    VSpacer,
    VAlert,
    VCheckbox,
    VSelect,
  },
  props: {
    form: {
      type: Form,
    },
    property: {
      type: Property,
      required: true,
    },
    unit: {
      type: Unit,
      required: true,
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      datePickerMenu: {} as Record<string, string>,
      datePickerDate: {} as Record<string, string>,
      statusIsError: false,
      encodedStatusText: '',
      submitting: false,
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.reset();
      },
    },
  },
  computed: {
    statusText(): string {
      const element = document.createElement('div');
      element.innerHTML = this.encodedStatusText;
      return element.innerText;
    }
  },
  methods: {
    getItems(values: { label: string, selected: boolean, value: string }[]) {
      return values.map(value => {
        return {
          label: value.label,
          value: value.value
        }
      });
    },
    startSubmission() {
      this.submitting = true;
    },
    invokeRecaptcha() {
      if (this.$refs.recaptcha) {
        (this.$refs.recaptcha as any).invokeChallenge();
      } else {
        this.statusText = 'An error has occurred. Please try again later'
        this.statusIsError = true
        this.submitting = false;
        console.warn('FPN: Recaptcha is missing can not invoke challenge.')
      }
    },
    resetRecaptcha() {
      if (this.$refs.recaptcha) {
        (this.$refs.recaptcha as any).resetRecaptcha();
      } else {
        this.statusText = 'An error has occurred. Please try again later'
        this.statusIsError = true
        this.submitting = false;
        console.warn('FPN: Recaptcha is missing can not reset it.')
      }
    },
    submitted() {
      this.submitting = false;
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    },
    success(message: string) {
      this.statusIsError = false;
      this.encodedStatusText = message;
      this.$emit('success');
    },
    error(message: string) {
      if (message === 'fix-inputs') {
        (this.$refs.form as any).validate();
      } else if (message !== 'captcha-issue') {
        this.statusIsError = true;
        this.encodedStatusText = message
          ? message
          : 'There was an error while submitting the form, please try again later';
      }
      this.submitting = false;
    },
    reset() {
      (this.$refs.form as any).reset();
      this.encodedStatusText = '';
      this.statusIsError = false;
      this.submitting = false;
    },
    allowedDates(value: string) {
      return new Date(value).getTime() > new Date().getTime();
    },
  },
});
