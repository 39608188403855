var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('RenderlessFormBuilder',{attrs:{"form":_vm.form,"property":_vm.property,"unit":_vm.unit,"lang":_vm.lang},on:{"reset-recaptcha":_vm.resetRecaptcha,"submitted":_vm.submitted,"success":_vm.success,"error":_vm.error,"invoke-recaptcha":_vm.invokeRecaptcha},scopedSlots:_vm._u([{key:"default",fn:function({
    captchaCompleted,
    captchaExpired,
    shouldRender,
    processForm,
    fieldUpdated,
    fieldset,
    fields,
    rules,
  }){return [(_vm.form && Object.keys(_vm.form).length > 0)?_c('VForm',{ref:"form",staticClass:"form-builder",class:`form-builder-default form-builder-default--${_vm.form.key}`,on:{"submit":function($event){$event.preventDefault();return processForm.apply(null, arguments)}}},[_c('fieldset',[(_vm.statusText)?_c('VAlert',{attrs:{"dense":"","text":"","type":_vm.statusIsError ? 'error' : 'success',"dismissible":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.statusText)}})]):_vm._e(),_vm._v(" "),_c('div',[(fieldset && false)?_c('legend',[_vm._v("\n          "+_vm._s(fieldset.legend)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._l((fields),function(field,index){return [(field.type === 'paragraph')?_c('p',{attrs:{"part":"form-paragraphs"}},[_vm._v("\n            "+_vm._s(field.label)+"\n          ")]):_vm._e(),_vm._v(" "),(shouldRender(field) && field.type === 'select')?_c('VSelect',{attrs:{"label":field.label,"items":_vm.getItems(field.values),"item-text":"label","item-value":"value","rules":rules[field.name]},on:{"input":function($event){return fieldUpdated($event, field)}}}):(shouldRender(field) && field.type === 'number')?_c('VTextField',{key:index,attrs:{"label":field.label,"type":"number","variant":"outlined","required":field.required,"rules":rules[field.name]},nativeOn:{"input":function($event){return fieldUpdated($event.target.value, field)}}}):(
              shouldRender(field) &&
              field.type === 'text' &&
              field.subtype !== 'date'
            )?_c('VTextField',{key:index,attrs:{"label":field.label,"type":field.subtype === 'date' ? 'date' : 'text',"variant":"outlined","required":field.required,"rules":rules[field.name]},nativeOn:{"input":function($event){return fieldUpdated($event.target.value, field)}}}):(
              shouldRender(field) &&
              field.type === 'text' &&
              field.subtype === 'date'
            )?_c('VMenu',{ref:`menu-${field.name}`,refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.datePickerDate[field.name],"transition":"scale-transition","min-width":"auto","attach":""},on:{"update:returnValue":function($event){return _vm.$set(_vm.datePickerDate, field.name, $event)},"update:return-value":function($event){return _vm.$set(_vm.datePickerDate, field.name, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('VTextField',_vm._g(_vm._b({attrs:{"label":field.label,"readonly":"","rules":rules[field.name]},model:{value:(_vm.datePickerDate[field.name]),callback:function ($$v) {_vm.$set(_vm.datePickerDate, field.name, $$v)},expression:"datePickerDate[field.name]"}},'VTextField',attrs,false),on))]}}],null,true),model:{value:(_vm.datePickerMenu[field.name]),callback:function ($$v) {_vm.$set(_vm.datePickerMenu, field.name, $$v)},expression:"datePickerMenu[field.name]"}},[_vm._v(" "),_c('VDatePicker',{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.allowedDates},on:{"input":function($event){return fieldUpdated($event, field)}},model:{value:(_vm.datePickerDate[field.name]),callback:function ($$v) {_vm.$set(_vm.datePickerDate, field.name, $$v)},expression:"datePickerDate[field.name]"}},[_c('VSpacer'),_vm._v(" "),_c('VBtn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.datePickerMenu[field.name] = false}}},[_vm._v("\n                "+_vm._s(_vm.$t('cancel'))+"\n              ")]),_vm._v(" "),_c('VBtn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs[`menu-${field.name}`][0].save(
                    _vm.datePickerDate[field.name]
                  )}}},[_vm._v("\n                "+_vm._s(_vm.$t('ok'))+"\n              ")])],1)],1):(shouldRender(field) && field.type === 'checkbox')?_c('VCheckbox',{key:field.name,attrs:{"label":field.label,"rules":rules[field.name],"required":field.required,"value":true},on:{"change":function($event){return fieldUpdated($event, field)}}}):(shouldRender(field) && field.type === 'textarea')?_c('VTextarea',{key:field.name,attrs:{"label":field.label,"type":"date","variant":"outlined","rules":rules[field.name],"required":field.required},nativeOn:{"input":function($event){return fieldUpdated($event.target.value, field)}}}):_vm._e()]})],2)],1),_vm._v(" "),_c('div',{staticClass:"submit-section"},[_c('RecaptchaDefault',{ref:"recaptcha",staticClass:"form-builder-default__recaptcha",on:{"success":captchaCompleted,"expired":captchaExpired,"error":captchaExpired,"formError":_vm.error}}),_vm._v(" "),_c('VBtn',{staticClass:"form-builder__submit",attrs:{"type":"submit","loading":_vm.submitting},on:{"click":_vm.startSubmission}},[_vm._v("\n        "+_vm._s(_vm.$t('submit'))+"\n      ")])],1)]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }