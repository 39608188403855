/**
 * Escape RegExp
 */
function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 *
 */
function interpolation(format: string, obj: Record<string, any>, value?: any) {
  let result = format;

  for (const key of Object.keys(obj)) {
    const regex = new RegExp(`\\[\\[\\s*${key}\\s*]]`, 'g');
    if (typeof obj[key] === 'string' && obj[key].length > 0) {
      result = result.replace(
        regex,
        obj[key][0].toUpperCase() + obj[key].slice(1)
      );
    } else {
      result = result.replace(regex, obj[key]);
    }
  }

  if (result.includes('[[ value ]]') || result.includes('[[value]]')) {
    const regex = new RegExp(`\\[\\[\\s*value\\s*]]`, 'g');

    if (value) {
      if (typeof value === 'string') {
        result = result.replace(regex, value[0].toUpperCase() + value.slice(1));
      } else {
        result = result.replace(regex, value);
      }
    } else {
      result = result.replace(regex, '');
    }
  }

  /**
   * Removes any extra interpolated variables, this is useful for when mistakes are made
   */
  return result.replace(/\[\[\s+[^\s]*\s+\]\]/gm, '');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
 *
 */
function conditionals(format: string, obj: Record<string, any>, value?: any) {
  let result = format;
  const conditionals = result.match(/(\[\[(?:(?!\|).)*?\?.*?]])/g);

  if (conditionals && conditionals.length > 0) {
    for (const conditional of conditionals) {
      const results = conditional
        .replace('[[ ', '')
        .replace(' ]]', '')
        .split(' ? ');
      const regex = new RegExp(escapeRegExp(conditional), 'g');

      if (obj[results[0]]) {
        result = result.replace(regex, results[1]);
      } else {
        result = result.replace(regex, '');
      }
    }
  }

  return result;
}

/**
 * This one works if no other form of interpolation is being used, otherwise this rule gets ignored
 *
 * @param format
 * @param obj
 * @param value
 * @example Studio | 1 Bedroom | [[ beds ]] Bedrooms
 */
function pluralization(format: string, obj: Record<string, any>, value?: any) {
  const matches = format.match(/\]\]|\[\[/g);

  // If it contains any form of interpolation at this point, ignore this step
  if (matches && matches.length > 0) {
    return format;
  }

  let result = format;

  let pluralization = format.match(/(.+?)(?: \| |$)/g);

  if (pluralization && pluralization.length > 1) {
    // Remove the ` | ` at the end of most entries, and unescape pipe characters
    pluralization = pluralization.map(words =>
      words.replace(/( \| )/g, '').replace(/\/\|/g, '|')
    );

    if (value !== undefined) {
      if (typeof value === 'number') {
        result =
          pluralization[
            value > pluralization.length - 1 ? pluralization.length - 1 : value
          ];
      } else if (pluralization.length === 2) {
        result = !value ? pluralization[0] : pluralization[1];
      }
    }
  }

  return result;
}

/**
 *
 */
function keyConditionals(
  format: string,
  obj: Record<string, any>,
  value?: any
) {
  let result = format;
  const regEx = new RegExp(/\[\[.*?\|.*?\]\]/g);
  const regexResult = format.match(regEx);

  if (regexResult && regexResult.length > 0) {
    for (const match of regexResult) {
      let tempMatch = match.substring(2, match.length - 2);

      if (tempMatch.includes('|>')) {
        const formatSplit = tempMatch.split('|>');

        if (obj[formatSplit[0].trim()] !== undefined) {
          value = obj[formatSplit[0].trim()];
        }

        tempMatch = formatSplit[1].trim();
      }

      const keyValues = tempMatch.split('|');

      for (let i = 0; i < keyValues.length; i++) {
        const keyValuesSplit = keyValues[i].split(':');
        const interpolationStringValue = keyValuesSplit[0].trim();
        const interpolationResult = keyValuesSplit[1].trim();

        // If it's not a catch all
        if (interpolationStringValue !== '') {
          const interpolationValue =
            typeof value === 'number'
              ? Number.parseInt(interpolationStringValue, 10)
              : interpolationStringValue;

          if (interpolationValue === value) {
            tempMatch = interpolationResult;
            break;
          }
        } else {
          tempMatch = interpolationResult;
        }
      }

      result = result.replace(match, tempMatch);
    }
  }

  return result;
}

/**
 *
 */
export function interpolate(
  format: string,
  obj: Record<string, any>,
  value?: any
): string | null {
  if (!format) {
    return format;
  }

  const steps: Function[] = [
    interpolation,
    conditionals,
    // TODO: This is for backwards compatibility only, and is currently deprecated.
    pluralization,
    keyConditionals,
  ];

  return steps.reduce((text, method) => method(text, obj, value), format);
}
