// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-74.use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-74.use[2]!../../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-74.use[3]!../../../../../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-74.use[4]!./VFooter.sass");
if(content.__esModule) content = content.default;
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!../../../../../../../node_modules/vue-style-loader/lib/addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("6db966d8", content, shadowRoot)
};