import { AvailabilityTypes } from '@/types/fields';
import { Unit } from '@/models/Unit';

/**
 *
 */
export function decodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

/**
 *
 */
export function availabilityToString(
  unit: Unit,
  available?: number
): AvailabilityTypes {
  switch (available) {
    case 1:
      return unit.availabilityDate !== '0000-00-00' && unit.availabilityDate
        ? 'availableOn'
        : 'available';
    case 0:
      return 'notAvailable';
    case -1:
      return 'waitlist';
  }
  console.log('Not found :(', available, unit);

  return '';
}

/**
 *
 */
export function formatDate(dateString: string): string {
  const [year, month, day] = dateString.split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day));

  const suffixes = ['th', 'st', 'nd', 'rd'];
  const suffix =
    suffixes[(parseInt(day) - 20) % 10] ||
    suffixes[parseInt(day)] ||
    suffixes[0];

  // formattedDate will be something like "January 1st, 2021" from an input of "2021-01-01"
  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
    .format(date)
    .replace(/\b(\d+)\b/, `${parseInt(day)}${suffix}`);
}

/**
 * Checks if the app is currently loaded in prod
 */
export function isProduction(): boolean {
  return !isNotProduction();
}

/**
 * Checks if the app is currently loaded in prod
 */
export function isNotProduction(checkForUrlParamKey = true): boolean {
  const urlParams = new URLSearchParams(window.location.search);

  return (
    (window.location.host === 'fpn.rentsync.com' && (checkForUrlParamKey ? urlParams.get('key') === null : true)) ||
    window.location.host.endsWith('lws1.com') ||
    window.location.host.endsWith('rentsync.dev') ||
    window.location.host.startsWith('localhost')
  );
}

export function getFpnSourceType(): string {
  if (window.location.hostname === 'fpn-pos.rentsync.com') {
    return 'pos'
  }

  if (window.location.hostname === 'rentals.ca') {
    return 'rentals'
  }

  return 'website'
}
