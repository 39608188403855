import { createI18n } from 'vue-i18n-composable';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

import { en, fr } from '@vuetify/lib/locale';
import fpnEn from '@/locales/en';
import fpnFr from '@/locales/fr';

Vue.use(VueI18n);

// Note: This is to circumvent
/**
 *
 */
function customRule(choice: number, choicesLength: number) {
  if (choice === 0) {
    return 0;
  }

  if (choice === 1) {
    return 1;
  }

  return 2;
}

export default createI18n({
  fallbackLocale: 'en',
  locale: 'en',
  messages: {
    en: {
      $vuetify: en,
      ...fpnEn,
    },
    fr: {
      $vuetify: fr,
      ...fpnFr,
    }
  },
  pluralizationRules: {
    en: customRule,
    fr: customRule,
  },
});
