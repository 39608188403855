
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { Floor } from '@/models/Floor';
import { decodeHtml } from '@/lib/helpers';

export default defineComponent({
  name: 'PropertyImage',
  props: {
    buildingImage: {
      type: String,
      required: true,
    },
    curFloor: {
      type: Floor,
      required: true,
    },
    floors: {
      type: Array as PropType<Array<Floor>>,
      required: true,
    },
  },
  emits: ['floor-click', 'set-ratio'],
  data() {
    return {
      viewbox: '0 0 1000 1000',
    };
  },
  methods: {
    decodeHtml(html: string) {
      return decodeHtml(html);
    },
    setRatio() {
      this.$emit('set-ratio', (this.$refs.buildingImage as any).naturalWidth / (this.$refs.buildingImage as any).naturalHeight);

      this.viewbox = `0 0 ${(this.$refs.buildingImage as any).naturalWidth} ${
        (this.$refs.buildingImage as any).naturalHeight
      }`;
    },
  },
});
